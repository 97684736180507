import React from 'react';

// Pagination component
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  // Generate page numbers array
  const getPageNumbers = () => {
    // If 5 or fewer pages, show all page numbers
    if (totalPages <= 5) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
    
    // If current page is among first 3 pages
    if (currentPage <= 3) {
      return [1, 2, 3, 4, 5];
    }
    
    // If current page is among last 3 pages
    if (currentPage >= totalPages - 2) {
      return [totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    }
    
    // Otherwise, show current page and 2 pages before and after
    return [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="pagination-container" style={styles.container}>
      {/* First page and previous page buttons */}
      <button 
        onClick={() => onPageChange(1)} 
        disabled={currentPage === 1}
        style={{...styles.navButton, opacity: currentPage === 1 ? 0.5 : 1}}
      >
        {'<<'}
      </button>
      <button 
        onClick={() => onPageChange(currentPage - 1)} 
        disabled={currentPage === 1}
        style={{...styles.navButton, opacity: currentPage === 1 ? 0.5 : 1}}
      >
        {'<'}
      </button>
      
      {/* Page numbers */}
      {pageNumbers.map(number => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          style={{
            ...styles.pageButton,
            ...(currentPage === number ? styles.activePage : {})
          }}
        >
          {number}
        </button>
      ))}
      
      {/* Next page and last page buttons */}
      <button 
        onClick={() => onPageChange(currentPage + 1)} 
        disabled={currentPage === totalPages}
        style={{...styles.navButton, opacity: currentPage === totalPages ? 0.5 : 1}}
      >
        {'>'}
      </button>
      <button 
        onClick={() => onPageChange(totalPages)} 
        disabled={currentPage === totalPages}
        style={{...styles.navButton, opacity: currentPage === totalPages ? 0.5 : 1}}
      >
        {'>>'}
      </button>
    </div>
  );
};

// Styles
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
    gap: '5px'
  },
  pageButton: {
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    background: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    color: '#2E7C9A',
    fontWeight: 'normal'
  },
  activePage: {
    background: 'rgb(1 182 205 / 0%)',
    color: '#01B6CD',
    fontWeight: 'bold'
  },
  navButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    color: '#B9C3C4',
    padding: '0 5px'
  }
};

export default Pagination;