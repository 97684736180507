import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import SendBird from 'sendbird';
import axios from 'axios';
import { ChevronLeft, ChevronRight } from 'lucide-react';

// Components
import NotificationComponent from './NotificationComponent';
import ChatComponent from './ChatComponent';
import UserCallComponent from './UserCallComponent';
import ChannelListComponent from './ChannelList';
import PatientInfo from './PatientInfo';
import PatientHistory from './PatientHistory';
import Modal from './Prescription/Modal';
import PrescriptionForm from './Prescription/PrescriptionForm';
import Preview from './Prescription/Preview';
import StopTreatmentModal from './Prescription/StopTreatmentModal';
import ExpiredCallPopup from './ExpiredCallPopup';
import PaymentNotificationPopup from './PaymentNotificationPopup';
import DiagnosisAlert from './DiagnosisAlert';
import SideDrProfile from './side_drProfile';
import SideMedicalDepartment from './side_MedicalDepartment';
import PatientHistoryPDF from './PatientHistoryPDF';
import { useSound } from '../hooks/useSound';
import downloadIcon from './icons/Download.png';
import arrow from './icons/arrow.png';
import arrow2 from './icons/arrow2.png';
import arrow3 from './icons/arrow3.png';

// CSS
import '../css/WebChatComponent.css';
import './Prescription/StopTreatmentModal.css';
import './Prescription/Preview.css';
import './Prescription/PrescriptionForm.css';
import '../css/IncomePage.css';

// Images
import logoImage from './icons/Logo.png';
import searchIcon from './icons/search-icon.png';

const changeEmail = (email) => email.replace('@', '^');
const IP = process.env.REACT_APP_HOST;
const sb = new SendBird({ appId: process.env.REACT_APP_APP_ID });

function WebChatComponent({ selectedChannelUrl, onSelectChannel }) {
  const navigate = useNavigate();
  const callSound = new Audio('/OccureCall.wav');
  const [selectedTab, setSelectedTab] = useState('active');
  const [searchQuery, setSearchQuery] = useState('');
  const [terminatedChannels, setTerminatedChannels] = useState([]);
  const [channels, setChannels] = useState([]);
  const [user, setUser] = useState(null);
  const [isUserCallAccepted, setIsUserCallAccepted] = useState(false);
  const [userCalls, setUserCalls] = useState([]);
  const [selectedUserCall, setSelectedUserCall] = useState(null);
  const [viewHistory, setViewHistory] = useState(false);
  const [isPrescriptionModalOpen, setPrescriptionModalOpen] = useState(false);
  const [isImpossibleModalOpen, setImpossibleModalOpen] = useState(false);
  const [patientNickname, setPatientNickname] = useState('');
  const [showExpiredCallPopup, setShowExpiredCallPopup] = useState(false);
  const [showPaymentNotification, setShowPaymentNotification] = useState(false);
  const [eventSource, setEventSource] = useState(null);
  const [fetchIntervalActive, setFetchIntervalActive] = useState(true);
  const [prescriptionFormData, setPrescriptionFormData] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [tabIndicatorStyle, setTabIndicatorStyle] = useState({});
  const tabsRef = useRef(null);
  const [inactiveDiagnoses, setInactiveDiagnoses] = useState({});
  const [isFrozen, setIsFrozen] = useState(false);
  const [filteredActiveChannels, setFilteredActiveChannels] = useState([]);
  const [filteredInactiveChannels, setFilteredInactiveChannels] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [unreadCounts, setUnreadCounts] = useState({});
  const [currentUserId, setCurrentUserId] = useState(null);
  const [activeChannelUrl, setActiveChannelUrl] = useState(null);
  const [showDrProfile, setShowDrProfile] = useState(false);
  const [showMedicalDepartment, setShowMedicalDepartment] = useState(false);
  const [channelStates, setChannelStates] = useState({});
  const [isDiagnosisAlertOpen, setIsDiagnosisAlertOpen] = useState(false);
  const [isChatActive, setIsChatActive] = useState(false);
  const [showPatientHistoryPDF, setShowPatientHistoryPDF] = useState(false);
  const [selectedPrescriptionUrl, setSelectedPrescriptionUrl] = useState('');
  const [messages, setMessages] = useState([]);
  const [data, setDrData] = useState({}); //의사 데이터
  const [infoData, setInfoData] = useState({}); //의사 개인정보 데이터
  const [ActData, setActData] = useState({}); // 의사 계정 설정 데이터
  const [MecData, setMdcData] = useState({});// 의사 의료 정보 설정 데이터
  const [sound, setSound] = useState();
  const email = localStorage.getItem('email');
  const [hasPlayedSound, setHasPlayedSound] = useState(false);
  const [expandedWeek, setExpandedWeek] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [weeklyIncome, setWeeklyIncome] = useState([]);
  const [dailyIncome, setDailyIncome] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [expandedDay, setExpandedDay] = useState(null);
  const [currentWeekIndex, setCurrentWeekIndex] = useState(0);
  const [patientProfileUrl, setPatientProfileUrl] = useState('');
  const [currentChatContent, setCurrentChatContent] = useState({
    messages: [],
    patientInfo: null,
    channelUrl: null
  });

  const formatCurrency = (amount) => `$ ${Number(amount).toFixed(2)}`;

  const formatDate = (date) => {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const d = new Date(date);
    return `${months[d.getMonth()]} ${d.getDate()}`;
  };

  const formatMonth = (date) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return months[new Date(date).getMonth()];
  };

  const handleWeekNavigation = (direction) => {
    if (direction === 'prev' && currentWeekIndex < weeklyIncome.length - 1) {
      setCurrentWeekIndex(prevIndex => prevIndex + 1);
      const prevWeek = weeklyIncome[currentWeekIndex + 1];
      setSelectedWeek(prevWeek);
      fetchDailyIncome(prevWeek.startDate, prevWeek.endDate);
    } else if (direction === 'next' && currentWeekIndex > 0) {
      setCurrentWeekIndex(prevIndex => prevIndex - 1);
      const nextWeek = weeklyIncome[currentWeekIndex - 1];
      setSelectedWeek(nextWeek);
      fetchDailyIncome(nextWeek.startDate, nextWeek.endDate);
    }
  };

  const handleWeekClick = (week) => {
    if (selectedWeek && selectedWeek.startDate === week.startDate) {
      setSelectedWeek(null);
      setExpandedWeek(null);
      setCurrentWeekIndex(0);
    } else {
      const index = weeklyIncome.findIndex(w => w.startDate === week.startDate);
      setCurrentWeekIndex(index);
      setSelectedWeek(week);
      fetchDailyIncome(week.startDate, week.endDate);
      setExpandedWeek(week.startDate);
    }
  };

  const handleDayClick = (date) => {
    setExpandedDay(expandedDay === date ? null : date);
  };

  const handleBackToWeekly = () => {
    setSelectedWeek(null);
    setExpandedDay(null);
  };

  const handleDownloadStatement = () => {
    // Implement PDF generation logic here
    console.log('Downloading statement...');
  };

  // getChannelName을 useCallback으로 감싸기
  const getChannelName = useCallback((channel, currentUserId) => {
    if (channel.name && channel.name !== 'Group Channel') {
      return channel.name;
    }
    const otherMembers = channel.members.filter(member => member.userId !== currentUserId);
    if (otherMembers.length > 0) {
      return otherMembers[0].nickname || otherMembers[0].userId;
    }
    return 'No name';
  }, []);

  const handlePatientSearch = (prescriptionUrl) => {
    setSelectedPrescriptionUrl(prescriptionUrl);
    setShowPatientHistoryPDF(true);
  };

  const handleClosePatientHistoryPDF = () => {
    setShowPatientHistoryPDF(false);
    setSelectedPrescriptionUrl('');
  };

  const handleSearchChange = useCallback((e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setIsSearching(query.length > 0);

    const filterChannels = (channelList) => {
      return channelList.filter(channel => {
        const channelName = getChannelName(channel, user?.userId).split('(')[0].trim().toLowerCase();
        return channelName.includes(query);
      });
    };

    setFilteredActiveChannels(filterChannels(channels));
    setFilteredInactiveChannels(filterChannels(terminatedChannels));
  }, [channels, terminatedChannels, user, getChannelName]);

  useEffect(() => {
    console.log('Current year:', year);
    console.log('Stored email:', localStorage.getItem('email'));
    fetchWeeklyIncome();
  }, [year]);

  const fetchWeeklyIncome = async () => {
    try {
      const response = await axios.post(`/DrIncome/weekly`,
        { drEmail: localStorage.getItem('email') },
        { withCredentials: true }
      );

      const weeklyData = Array.isArray(response.data) ? response.data : [];
      const sortedData = weeklyData.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

      setWeeklyIncome(sortedData);
      const total = sortedData.reduce((sum, week) => sum + week.totalAmount, 0);
      setTotalAmount(total);
    } catch (error) {
      console.error('Error fetching weekly income:', error);
    }
  };

  const fetchDailyIncome = async (startDate, endDate) => {
    try {
      const response = await axios.post(`/DrIncome/daily`, {
        drEmail: localStorage.getItem('email'),
        startDate,
        endDate
      }, {
        withCredentials: true
      });

      const filledData = fillMissingDates(response.data, new Date(startDate), new Date(endDate));
      setDailyIncome(filledData);
    } catch (error) {
      console.error('Error fetching daily income:', error);
    }
  };

  const fetchChannels = useCallback(async () => {
    if (!user) return;

    try {
      const channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
      channelListQuery.includeEmpty = true;
      channelListQuery.limit = 20;

      const groupChannels = await new Promise((resolve, reject) => {
        channelListQuery.next((channels, error) => {
          if (error) reject(error);
          else resolve(channels);
        });
      });

      // 채널 상태를 확인하고 필터링
      const activeChannels = groupChannels.filter(channel => !channel.isFrozen);
      const inactiveChannels = groupChannels.filter(channel => channel.isFrozen);

      setChannels(activeChannels);
      setTerminatedChannels(inactiveChannels);

      handleSearchChange({ target: { value: searchQuery } });

      const newUnreadCounts = {};
      groupChannels.forEach(channel => {
        if (channel.unreadMessageCount > 0) {
          newUnreadCounts[channel.url] = channel.unreadMessageCount;
        }
      });
      setUnreadCounts(prev => ({ ...prev, ...newUnreadCounts }));
    } catch (error) {
      console.error('Error fetching channels:', error);
    }
  }, [user, searchQuery, handleSearchChange]);

  const fetchInactiveDiagnoses = useCallback(async () => {
    if (selectedTab === 'inactive') {
      console.log('Fetching inactive diagnoses for channels');
      try {
        const diagnoses = {};
        for (const channel of terminatedChannels) {
          const groupChannel = await sb.GroupChannel.getChannel(channel.url);
          const members = groupChannel.members;

          const drEmail = email;
          const drSbEmail = data.dr_sb_uuid;
          const userEmail = members.find(member => member.userId !== drSbEmail).userId;

          const response = await axios.get(`/DrChatList/ReturnInactive`, {
            params: { drEmail, userEmail: changeID(userEmail) }
          });
          console.log('Response for channel', channel.url, ':', response.data);

          if (response.data.results && response.data.results.length > 0) {
            diagnoses[channel.url] = response.data.results[0].userDisease;
          }
        }
        console.log('Processed diagnoses:', diagnoses);
        setInactiveDiagnoses(diagnoses);
      } catch (error) {
        console.error('Error fetching inactive diagnoses:', error.response || error);
        setInactiveDiagnoses({});
      }
    }
  }, [selectedTab, terminatedChannels, email]);

  // 사용자 호출 수락 처리
  const handleAcceptCall = async (userCall) => {
    setFetchIntervalActive(true);

    try {
      const response = await axios.post('/DrChatMain/callAccept', {
        userEmail: userCall.userId,
        drEmail: email,
      });

      if (response.status === 200) {
        const userIdSB = changeEmail(userCall.userId); // SendBird 사용자 ID 변경
        const drIdSB = data.dr_sb_uuid; // SendBird 의사 ID 변경

        const channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
        channelListQuery.userIdsExactFilter = [userIdSB, drIdSB]; // 채널 목록 쿼리 설정

        try {
          const [groupChannels] = await new Promise((resolve, reject) => {
            channelListQuery.next((channels, error) => {
              if (error) reject(error);
              else resolve([channels]);
            });
          });

          let channel;
          if (groupChannels.length > 0) {
            channel = groupChannels[0];
          } else {
            channel = await new Promise((resolve, reject) => {
              sb.GroupChannel.createChannel([userIdSB, drIdSB], (createdChannel, error) => {
                if (error) reject(error);
                else resolve(createdChannel);
              });
            });
            setChannels(prevChannels => [...prevChannels, channel]);
          }

          // 채널 선택 및 채팅 내용 업데이트
          await handleSelectChannel(channel.url);
          setSelectedUserCall(userCall);
          setFetchIntervalActive(false);
        } catch (error) {
          console.error('Error in channel operations:', error);
        }
      }
    } catch (error) {
      console.error('Error accepting user call:', error);
      if (error.response && error.response.status === 400) {
        setShowExpiredCallPopup(true);
      }
    }
  };

  const updateChatContent = async (channelUrl, userCall) => {
    try {
      const channel = await sb.GroupChannel.getChannel(channelUrl);
      const messageList = await channel.getMessagesByTimestamp(Date.now(), {
        prevResultSize: 100,
        nextResultSize: 0
      });

      const translatedMessages = await Promise.all(messageList.map(async (message) => {
        try {
          const translatedMsg = await message.translations['en'];
          return { ...message, translatedMessage: translatedMsg };
        } catch (error) {
          console.error('Translation error:', error);
          return message;
        }
      }));

      // 환자 정보 가져오기
      const response = await axios.post('/api/getUserCallInfo', {
        channelUrl: channelUrl
      });

      if (response.data?.chatPrgrs?.[0]) {
        const chatPrgrs = response.data.chatPrgrs[0];

        // 환자 프로필 이미지 업데이트
        if (chatPrgrs.iconImage) {
          const fullProfileUrl = `/icons/${chatPrgrs.iconImage}_Active.png`;
          setPatientProfileUrl(fullProfileUrl);
        }

        // 채팅 컨텐츠 업데이트
        setCurrentChatContent({
          messages: translatedMessages,
          patientInfo: chatPrgrs,
          channelUrl: channelUrl
        });

        // 환자 닉네임 업데이트 (필요한 경우)
        if (chatPrgrs.userCall?.[0]?.userName) {
          setPatientNickname(chatPrgrs.userCall[0].userName);
        }
      }

      setIsFrozen(channel.isFrozen);
    } catch (error) {
      console.error('Error updating chat content:', error);
      // 에러 발생 시 기본값 설정
      setPatientProfileUrl('');
      setPatientNickname('Unknown User');
      setCurrentChatContent({
        messages: [],
        patientInfo: null,
        channelUrl: channelUrl
      });
      setIsFrozen(false);
    }
  };

  const handleCancelCall = (userCall) => {
    setIsUserCallAccepted(false);
    setSelectedUserCall(null);
  };

  const handlePrescriptionCancel = () => {
    setShowPreview(false);
  };

  function changeID(email) {
    return email.replace('^', '@');
  }

  const handleSelectChannel = async (url) => {
    setFetchIntervalActive(false);
    onSelectChannel(url);
    setActiveChannelUrl(url);
    setIsChatActive(true);  // 채팅이 시작될 때 상태를 true로 설정

    try {
      const channel = await sb.GroupChannel.getChannel(url);
      setIsUserCallAccepted(true);

      // 채널을 읽음으로 표시
      await channel.markAsRead();
      setUnreadCounts(prev => ({ ...prev, [url]: 0 }));

      // getUserCallInfo API 호출
      const response = await axios.post(`/api/getUserCallInfo`, {
        channelUrl: url,
      });

      if (response.data?.chatPrgrs?.[0]) {
        const chatPrgrs = response.data.chatPrgrs[0];

        // 채팅 내용 업데이트
        await updateChatContent(url, chatPrgrs.userCall?.[0]);

        // 환자 정보 설정
        if (chatPrgrs.userCall?.[0]) {
          setSelectedUserCall(chatPrgrs.userCall[0]);
        }
      }

      setIsFrozen(channel.isFrozen);

    } catch (error) {
      console.error('Error in handleSelectChannel:', error);
      setSelectedUserCall(null);
      setIsFrozen(false);
      setPatientNickname('Unknown User');
    }
  };

  const toggleHistoryView = () => {
    if (selectedUserCall) {
      setViewHistory(!viewHistory);
    }
  };

  const handleOpenPrescriptionModal = (userCall) => {
    setPrescriptionModalOpen(true);
    setSelectedUserCall(userCall);
  };

  const handleClosePrescriptionModal = () => {
    setPrescriptionModalOpen(false);
    setPrescriptionFormData(null);
    setShowPreview(false);
  };

  const handleCloseImpossibleModal = () => {
    setImpossibleModalOpen(false);
  };

  const handleStopTreatment = async () => {
    try {
      // 채널 종료 처리가 완료된 후
      setImpossibleModalOpen(false);
      setShowPaymentNotification(true);

      // 채널 목록 갱신을 위해 fetchIntervalActive를 true로 설정
      setFetchIntervalActive(true);

      // 채널 목록 즉시 갱신
      await fetchChannels();

      // 현재 선택된 채널 초기화
      setActiveChannelUrl(null);
      setSelectedUserCall(null);
    } catch (error) {
      console.error('Error stopping treatment:', error);
    }
  };

  const fillMissingDates = (data, startDate, endDate) => {
    const filledData = [];
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const existingData = data.find(item => new Date(item._id).toDateString() === currentDate.toDateString());
      if (existingData) {
        filledData.push(existingData);
      } else {
        filledData.push({
          _id: currentDate.toISOString(),
          amount: 0,
          cases: 0,
          treatments: []
        });
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return filledData;
  };

  const handlePrescriptionSubmit = (data) => {
    setPrescriptionFormData(data);
    setShowPreview(true);
  };

  const handleClick = () => {
    window.location.reload();
  };

  const handleProfileClick = () => {
    setShowDrProfile(true);
  };

  const handleCloseDrProfile = () => {
    setShowDrProfile(false);
  };

  const handleOpenMedicalDepartment = () => {
    setShowMedicalDepartment(true);
  };

  const handleCloseMedicalDepartment = () => {
    setShowMedicalDepartment(false);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    updateTabIndicator(tab);
  };

  const handleMessageSent = (channel) => {
    setUnreadCounts(prev => ({ ...prev, [channel.url]: 0 }));
  };

  const handleChannelChanged = (channel) => {
    setUnreadCounts(prev => ({ ...prev, [channel.url]: 0 }));
  };

  const handleExitChat = async () => {
    setShowPaymentNotification(false);
    setIsUserCallAccepted(false);
    setSelectedUserCall(null);
    setIsChatActive(false);

    // 채널 목록 갱신을 위해 fetchIntervalActive를 true로 설정
    setFetchIntervalActive(true);

    // 채널 목록 즉시 갱신
    await fetchChannels();
  };

  const updateTabIndicator = (tab) => {
    if (tabsRef.current) {
      const activeTab = tabsRef.current.querySelector(`[data-tab="${tab}"]`);
      if (activeTab) {
        const { offsetLeft, offsetWidth } = activeTab;
        setTabIndicatorStyle({
          left: `${offsetLeft}px`,
          width: `${offsetWidth}px`,
        });
      }
    }
  };

  const toggleSound = async () => {
    try {
      // DB 업데이트
      await axios.post('/Save/updateSound', {
        email: email,
        soundActive: !sound
      }, {
        withCredentials: true
      });
  
      // 상태 업데이트
      setSound(!sound);
      setHasPlayedSound(false);
      
      // 음소거 상태 업데이트
      if (callSound) {
        callSound.muted = sound;
      }
    } catch (error) {
      console.error('Error updating sound settings:', error);
    }
  };

  const goMain = () => {
    //navigate('/WebChat');
    window.location.reload();
  }

  const handleSendPrescription = useCallback(() => {
    setChannelStates(prev => ({
      ...prev,
      [selectedChannelUrl]: { diagnosisSent: true, prescriptionSent: true }
    }));
    handleClosePrescriptionModal();
  }, [selectedChannelUrl]);

  /*const handleDiagnosisSent = useCallback(() => {
    setChannelStates(prev => ({
      ...prev,
      [selectedChannelUrl]: { ...prev[selectedChannelUrl], diagnosisSent: true }
    }));
    handleClosePrescriptionModal();
  }, [selectedChannelUrl]);*/

  const GoToIncome = () =>{
    navigate('/pay');
  }

  const handleDiagnosisSent = useCallback(() => {
    setChannelStates(prev => ({
      ...prev,
      [selectedChannelUrl]: { ...prev[selectedChannelUrl], diagnosisSent: true }
    }));
  }, [selectedChannelUrl]);

  const handleOpenImpossibleModal = useCallback(async () => {
    try {
      const response = await axios.post('/ChatEnd/chatState', {
        channelUrl: selectedChannelUrl
      });

      if (response.status === 200) {
        fetchChannels();
        setImpossibleModalOpen(true);
      } else if (response.status === 201) {
        setIsDiagnosisAlertOpen(true);
      }
    } catch (error) {
      console.error('Error checking chat state:', error);
      // 에러 발생 시 진단을 하지 않은 것으로 간주
      setIsDiagnosisAlertOpen(true);
    }
  }, [selectedChannelUrl, fetchChannels]);

  const handleCloseDiagnosisAlert = () => {
    setIsDiagnosisAlertOpen(false);
  };
  
  useEffect(() => {
    setHasPlayedSound(false); // 페이지 새로고침시 재생 상태 초기화
    
    return () => {
      callSound.pause();
      callSound.currentTime = 0;
    };
  }, []);

  useEffect(() => {
    const fetchDoctorInfo = async () => {
      try {
        const response = await axios.get('/api/session-email', { withCredentials: true });
        if (response.status === 401) {
          navigate('/Login');
          return;
        }

        const Dremail = response.data.email;

        const DrInfoResponse = await axios.post('/Save/getDrInfo', { drEmail: Dremail }, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // Doctor's information
        const doctorInfo = DrInfoResponse.data.dr;
        setDrData(doctorInfo);
        const doctorInfo2 = DrInfoResponse.data.drInfo;
        setInfoData(doctorInfo2);
        const doctorActInfo = DrInfoResponse.data.drActInfo;
        setActData(doctorActInfo);
        const doctorMdcInfo = DrInfoResponse.data.drMdcInfo;
        setMdcData(doctorMdcInfo);

        // sound 상태 초기화 추가
        setSound(doctorInfo.dr_soundActive || false);

        // dr_sb_email 필드 값 가져오기
        const drSbEmail = doctorInfo.dr_sb_uuid;
        const drName = `${doctorInfo2.dr_firstname} ${doctorInfo2.dr_lastname}`
        //const drSbEmail = doctorInfo.userId;

        const connectedUser = await sb.connect(drSbEmail, 'email');
        sb.updateCurrentUserInfo(drName, null, (response, error) => {
          if (error) {
            console.error('Error updating user info:', error);
            return;
          }
          setUser(connectedUser);
        });
      } catch (error) {
        console.error('Error fetching doctor info:', error);
      }
    };

    fetchDoctorInfo();
  }, []);

  useEffect(() => {
    fetchInactiveDiagnoses();
  }, [selectedTab, terminatedChannels, fetchInactiveDiagnoses]);

  useEffect(() => {
    const eventSource = new EventSource(`/api/sse?email=${email}`);
    console.log(`/api/sse?email=${email}`);
  
    eventSource.onmessage = (event) => {
      try {
        const newEvent = JSON.parse(event.data);
        // 새로운 콜이 있고, 소리가 켜져있고, 아직 재생되지 않았을 때만 소리 재생
        if (newEvent.length > userCalls.length && sound && !hasPlayedSound) {
          callSound.play()
            .then(() => {
              setHasPlayedSound(true);
            })
            .catch(error => {
              console.error('Error playing sound:', error);
            });
        }
        setUserCalls(newEvent);
      } catch (e) {
        console.error('Failed to parse event data:', e);
      }
    };
  
    eventSource.onerror = (error) => {
      console.error('EventSource failed: ', error);
      eventSource.close();
    };
    setEventSource(eventSource);
    return () => {
      eventSource.close();
    };
  }, [email, sound, userCalls.length, hasPlayedSound]);

  useEffect(() => {
    updateTabIndicator(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (fetchIntervalActive) {
        fetchChannels();
      }
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, [searchQuery, selectedTab, user, fetchIntervalActive, fetchChannels]);

  useEffect(() => {
    if (user) {
      setCurrentUserId(user.userId);
      const channelHandler = new sb.ChannelHandler();
      channelHandler.onMessageReceived = (channel, message) => {
        try {
          if (message && message.sender && message.sender.userId &&
            message.sender.userId !== currentUserId &&
            channel.url !== activeChannelUrl) {
            setUnreadCounts(prev => ({
              ...prev,
              [channel.url]: (prev[channel.url] || 0) + 1
            }));
          }
        } catch (error) {
          console.error('Error in onMessageReceived:', error);
        }
      };
      sb.addChannelHandler('UNIQUE_HANDLER_ID', channelHandler);

      return () => {
        sb.removeChannelHandler('UNIQUE_HANDLER_ID');
      };
    }
  }, [user, currentUserId, activeChannelUrl]);

  return (
    <div className="wcc-app-container">
      <div className="wcc-header">
        <div className="wcc-header-left">
          <button onClick={goMain} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
            <img src={logoImage} alt="MEDINOX Logo" className="wcc-logo-image" />
          </button>
        </div>
        <div className="wcc-header-right">
          <button 
            className='wcc_sounds'
            onClick={toggleSound}
            style={{display:"contents"}}>
            <img 
              src={sound ? "./icons/web_icon/sound_on.png" : "./icons/web_icon/sound_mute.png"} 
              alt={sound ? "Sound On" : "Sound Off"} 
              style={{width:"32px", height:"auto"}}
            />
          </button>
          <button 
            className='wcc_incomeBtn'
            onClick={GoToIncome}>Income</button>
          <NotificationComponent
            userCalls={userCalls}
            handleAcceptCall={handleAcceptCall}
            handleCancelCall={handleCancelCall}
            isChatActive={isChatActive}
          />
          <div className="wcc-rate-container">
            <img src="./icons/web_icon/img_rate.png" alt="TTABONG" className="wcc-rate-img" />
            <div className="wcc-rate-text"><span style={{ color: "#01B6CD" }}>{infoData.dr_rating}</span> / 5</div>
          </div>
          <button className="wcc-drprofile-button" onClick={handleProfileClick}>
            <img
              loading="lazy"
              src={`${infoData.dr_profile_photo}`}
              alt="Doctor Profile"
              className="wcc-drprofile-img"
            />
          </button>
        </div>
        {showDrProfile && (
          <>
            <SideDrProfile
              drInfo={{
                name: `${infoData.dr_firstname} ${infoData.dr_lastname} `,
                profileImage: `${infoData.dr_profile_photo}`,
                dr_year: `${infoData.dr_birth[0].dr_year}`,
                dr_month: `${infoData.dr_birth[0].dr_month}`,
                dr_day: `${infoData.dr_birth[0].dr_day}`,
                gender: `${infoData.dr_gender}`,
                hospitalAddress: `${infoData.dr_live[0].dr_address}, ${infoData.dr_live[0].dr_state}, ${infoData.dr_live[0].dr_city}, ${infoData.dr_live[0].dr_zipcode}`,
                hospitalAddress2: `${infoData.dr_live[0].dr_address2}`,
                medicalDepartment: `${MecData.dr_medical_department}`,
                experience: `${MecData.dr_info_experience}`,
                greeting: `${MecData.dr_self_introduction}`,
                closingRemarks: `${MecData.dr_self_outro}`,
                PhoneNumber: `${infoData.dr_phonenumber}`,
                doctorType: `${MecData.dr_doctorType}`,
              }}
              onClose={handleCloseDrProfile}
              isOpen={showDrProfile}
              onOpenMedicalDepartment={handleOpenMedicalDepartment}
            />
          </>
        )}
      </div>
      {showMedicalDepartment && (
        <SideMedicalDepartment
          open={showMedicalDepartment}
          close={handleCloseMedicalDepartment}
          drInfo={{ medicalDepartment: `${MecData.dr_medical_department}` }}
        />
      )}
      <div className="wcc-content">
        <div className="wcc-sidebar">
          <div className="wcc-search">
            <div className="wcc-gene-div">
              <div className="wcc-search-container">
                <img src={searchIcon} alt="Search" className="wcc-search-icon" />
                <input
                  type="text"
                  className="wcc-search-input"
                  placeholder="Search here"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
          <div className="wcc-tabs" ref={tabsRef}>
            <button className={`tab ${selectedTab === 'active' ? 'active' : ''}`}
              onClick={() => handleTabChange('active')} data-tab="active">
              Active
              {isSearching && selectedTab === 'inactive' && filteredActiveChannels.length > 0 &&
                <span className="other-tab-count">{filteredActiveChannels.length}</span>}
            </button>
            <button
              className={`tab ${selectedTab === 'inactive' ? 'active' : ''}`}
              onClick={() => handleTabChange('inactive')} data-tab="inactive" >
              Inactive
              {isSearching && selectedTab === 'active' && filteredInactiveChannels.length > 0 &&
                <span className="other-tab-count">{filteredInactiveChannels.length}</span>}
            </button>
            <div className={`tab-indicator ${selectedTab}`} style={tabIndicatorStyle}></div>
          </div>
          <ChannelListComponent
            channels={selectedTab === 'active' ? filteredActiveChannels : filteredInactiveChannels}
            onSelectChannel={handleSelectChannel}
            activeTab={selectedTab}
            inactiveDiagnoses={inactiveDiagnoses}
            unreadCounts={unreadCounts}
            getChannelName={getChannelName}
            currentUser={user}
            selectedChannelUrl={activeChannelUrl}
          />
        </div>
        <div className="wcc-main-content">
          {!isUserCallAccepted ? (
            <div>
              <div className="wcc-call-list">
                <div className="wcc-call-list-header">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="./icons/web_icon/CallListBell.png" alt="Call" className="wcc-call-icon" />
                    <span className="wcc-call-list-title">Call list</span>
                  </div>
                  <span className="wcc-call-count">{userCalls.length} Calls</span>
                </div>
              </div>
              <div className="wcc-user-calls-list">

                {userCalls.map(userCall => (
                  <UserCallComponent
                    key={userCall.id}
                    userCall={userCall}
                    handleAcceptCall={() => handleAcceptCall(userCall)}
                    handleCancelCall={() => handleCancelCall(userCall)}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="wcc-chat-container">
              {isUserCallAccepted && (
                <ChatComponent
                  appId={process.env.REACT_APP_APP_ID}
                  userId={data.dr_sb_uuid}
                  channelUrl={activeChannelUrl}
                  patientNickname={patientNickname}
                  isFrozen={isFrozen}
                  messages={currentChatContent.messages}
                  patientProfileUrl={patientProfileUrl}
                />
              )}
              <div className="wcc-patient-info-container">
                {viewHistory ? (
                  <PatientHistory
                    userCall={selectedUserCall}
                    onBack={toggleHistoryView}
                    onPatientSearch={handlePatientSearch}
                  />
                ) : (
                  <PatientInfo
                    userCall={selectedUserCall}
                    onViewHistory={toggleHistoryView}
                    onOpenModal={handleOpenPrescriptionModal}
                    onImpossibleTreatment={handleOpenImpossibleModal}
                    isChannelFrozen={isFrozen}
                    patientInfo={currentChatContent.patientInfo}
                    patientProfileUrl={patientProfileUrl}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal isOpen={isPrescriptionModalOpen} onClose={handleClosePrescriptionModal}>
        {showPreview ? (
          <Preview
            data={prescriptionFormData}
            userInfo={selectedUserCall}
            channelUrl={selectedChannelUrl}
            onClose={handlePrescriptionCancel}
            onSend={handleSendPrescription}
            sb={sb}
            drEmail={email}
            drInfo={data}
            userCall={selectedUserCall}
          />
        ) : (
          <PrescriptionForm
            onSubmit={handlePrescriptionSubmit}
            userInfo={selectedUserCall}
            channelUrl={selectedChannelUrl}
            onClose={handleClosePrescriptionModal}
            drEmail={email}
            drInfo={data}
            initialData={prescriptionFormData}
            onDiagnosisSent={handleDiagnosisSent}
          />
        )}
      </Modal>
      <StopTreatmentModal
        isOpen={isImpossibleModalOpen}
        onClose={handleCloseImpossibleModal}
        onConfirm={handleStopTreatment}
        channelUrl={selectedChannelUrl}
        drEmail={email}
        userEmail={selectedUserCall ? selectedUserCall.userId : ''}
      />
      <DiagnosisAlert
        isOpen={isDiagnosisAlertOpen}
        onClose={handleCloseDiagnosisAlert}
      />
      {showExpiredCallPopup && (
        <ExpiredCallPopup onClose={() => setShowExpiredCallPopup(false)} />
      )}
      <PaymentNotificationPopup
        isOpen={showPaymentNotification}
        onClose={() => setShowPaymentNotification(false)}
        onExitChat={handleExitChat}
        url={selectedChannelUrl}
      />
      {showPatientHistoryPDF && (
        <PatientHistoryPDF
          prescriptionUrl={selectedPrescriptionUrl}
          onClose={handleClosePatientHistoryPDF}
        />
      )}
    </div>
  );
}

export default WebChatComponent;