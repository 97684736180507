import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../css/PreCon_SelectDepartment.css';

const departments = [
    { name: "Allergy and Immunology", icon: 'Allergy_and_Immunology' },
    { name: "Cardiology", icon: 'Cardiology' },
    { name: "Cardiothoracic Surgery", icon: 'Cardiothoracic_Surgery'},
    { name: "Dermatology", icon: 'Dermatology' },
    { name: "Emergency Medicine", icon: 'Emergency_Medicine' },
    { name: "Endocrinology", icon: 'Endocrinology' },
    { name: "Family Medicine", icon: 'Family_Medicine' },
    { name: "Gastroenterology", icon: 'Gastroenterology' },
    { name: "General Surgery", icon: 'General_Surgery' },
    { name: "Hematology", icon: 'Hematology' },
    { name: "Infectious Disease", icon: 'Infectious_Disease' },
    { name: "Internal Medicine", icon: 'Internal_Medicine' },
    { name: "Nephrology", icon: 'Nephrology' },
    { name: "Neurology", icon: 'Neurology' },
    { name: "Neurosurgery", icon: 'Neurosurgery' },
    { name: "Obstetrics and Gynecology", icon: 'Obstetrics_and_Gynecology' },
    { name: "Oncology", icon: 'Oncology' },
    { name: "Ophthalmology", icon: 'Ophthalmology' },
    { name: "Orthopedic Surgery", icon: 'Orthopedic_Surgery' },
    { name: "Orthopedics", icon: 'Orthopedic_Surgery' },
    { name: "Otolaryngology / ENT", icon: 'Otolaryngology_ENT'},
    { name: "Pediatrics", icon: 'Pediatrics' },
    { name: "Physical Medicine and Rehabilitation", icon: 'Physical_Medicine_Rehabilitation' },
    { name: "Plastic Surgery", icon: 'Plastic_Surgery' },
    { name: "Psychiatry", icon: 'Psychiatry' },
    { name: "Pulmonary Medicine", icon: 'Pulmonary_Medicine' },
    { name: "Rheumatology", icon: 'Rheumatology' },
    { name: "Urology", icon: 'Urology' }
];

function PreCon_SelectDepartment({formData, drName, drInfo, userEmail, medical_id, btnCancle, btnNext}){
    // 내부 상태로 departments 관리 - 다중 선택을 위해 배열로 관리
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredDepartments, setFilteredDepartments] = useState(departments);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const searchInputRef = useRef(null);
    
    // 검색어가 변경될 때마다 필터링된 부서 목록 업데이트
    useEffect(() => {
        if (searchQuery.trim() === '') {
            setFilteredDepartments(departments);
        } else {
            const filtered = departments.filter(dept => 
                dept.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredDepartments(filtered);
        }
    }, [searchQuery]);

    // 부서 선택 핸들러 - 다중 선택 처리
    const handleDepartmentSelect = (department) => {
        setSelectedDepartments(prev => {
            // 이미 선택된 부서인 경우 제거, 아니면 추가
            if (prev.includes(department)) {
                return prev.filter(dept => dept !== department);
            } else {
                return [...prev, department];
            }
        });
    };

    // 취소 버튼 핸들러
    const handleCancel = () => {
        // 상태 초기화
        setSelectedDepartments([]);
        setSearchQuery('');
    };

    // 전송 버튼 핸들러
    const handleSend = async() => {
        if (selectedDepartments.length === 0) {
            alert("Please select at least one department for the patient's diagnosis.");
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            // 선택된 진료과와 formData, drName을 함께 서버로 전송
            const response = await axios.post('/MedicationDepCall', {
                userEmail,
                selectedDepartments,
                formData,
                medical_id
            });

            console.log('Send successful:', response.data);
            
            // 성공 후 다음 단계로 이동 (btnNext가 있는 경우 실행)
            if (btnNext) {
                btnNext(selectedDepartments);
            }
            
        } catch (err) {
            console.error('Error sending department selection:', err);
            setError('Failed to send department selection. Please try again.');
            alert('Failed to send department selection. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return(
        <div className='precon-container'>
            <div className='precon-title'>Select Medical Department</div>
            <div className="precon-subtitle">Please select the department for the patient's diagnosis.</div>
            
            {/* 검색창 */}
            <div className="precon-search-container">
                <input
                    ref={searchInputRef}
                    type="text"
                    className="precon-search-input"
                    placeholder="Tap to search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <svg className="precon-search-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
            </div>
            
            {/* 부서 목록 */}
            <div className="precon-department-container">
                <div className="precon-department-grid">
                    {filteredDepartments.map((dept) => (
                        <button
                            type='button'
                            key={dept.name}
                            className={`precon-department-button ${selectedDepartments.includes(dept.name) ? 'active' : ''}`}
                            onClick={() => handleDepartmentSelect(dept.name)}
                        >
                            <img 
                                src={`./icons/departments/${dept.icon}${selectedDepartments.includes(dept.name) ? '_Active' : '_DeActive'}.png`} 
                                alt={dept.name} 
                                className="precon-department-icon"
                            />
                            <span className="precon-department-name">{dept.name}</span>
                        </button>
                    ))}
                </div>
            </div>
            
            {/* 버튼 영역 */}
            <div className='precon-button-center'>
                <button type="button" onClick={btnCancle} className="precon_cancel-button">
                    Prev
                </button>
                <button type="button" onClick={btnNext} className="precon_next-button">
                    Payment Request
                </button>
            </div>
        </div>
    );
}

export default PreCon_SelectDepartment;