import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/PatientInfo.css';
import '../css/PreExamination.css';

import PreConsultation from './PreConsultation';

const IP = process.env.REACT_APP_HOST;

function PreExamination({ userCall, chatPrgrs, drInfo, openCompleteConsultation, onViewEventHistory, onOpenModal, onImpossibleTreatment, isChannelFrozen,
  setMacroPQ_1,
  setMacroPQ_2,
  setMacroPQ_3,
  setMacroPQ_4,
  setMacroPQ_5,
  setMacroPQ_6,
  setMacroPQ_7,
  setMacroPQ_8,
  setMacroPQ_9,
  setMacroPQ_10,
  setMacroPQ_11,
  setMacroPQ_12,
  setMacroPQ_13,
 }) {

  const [MacroPannel1, openMacroPannel_1] = useState(false);
  const [MacroPannel2, openMacroPannel_2] = useState(false);
  const [MacroPannel3, openMacroPannel_3] = useState(false);
  const [MacroPannel4, openMacroPannel_4] = useState(false);
  const [MacroPannel5, openMacroPannel_5] = useState(false);
  const [MacroPannel6, openMacroPannel_6] = useState(false);
  const [MacroPannel7, openMacroPannel_7] = useState(false);
  const [MacroPannel8, openMacroPannel_8] = useState(false);
  const [MacroPannel9, openMacroPannel_9] = useState(false);
  const [MacroPannel10, openMacroPannel_10] = useState(false);
  const [MacroPannel11, openMacroPannel_11] = useState(false);
  const [MacroPannel12, openMacroPannel_12] = useState(false);
  const [MacroPannel13, openMacroPannel_13] = useState(false);

  const [patientData, setPatientData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (userCall && userCall.userInfo.user_firstname) {
      loadPatientData();
    }

    console.log(drInfo);
  }, [userCall]);

  // Function to load patient data from the server
  const loadPatientData = () => {
    setLoading(true);
    console.log(userCall.user.user_email);
    // You may need to adjust the endpoint and parameters based on your API
    axios.post(`/UserProfile/getUserMedicalInfo`, {
      userEmail: userCall.user.user_email
    })
    .then(response => {
      console.log('Patient data loaded successfully:', response.data);
      console.log('past_history: ',response.data.userMedical);
      setPatientData(response.data);
      
      // Populate form fields with the loaded data
      populateFormFields(response.data);
      
      setLoading(false);
    })
    .catch(error => {
      console.error('Error loading patient data:', error);
      setError('Failed to load patient data');
      setLoading(false);
    });
  };

  // Function to populate form fields with loaded data
  const populateFormFields = (data) => {
    console.log('Populating form fields with data:', data);
    
    // 데이터가 직접 emrInfo 배열인 경우
    if (Array.isArray(data) && data.length > 0) {
      const entry = data[0];
      processEntryData(entry);
      return;
    }
    
    // 데이터가 response.data 형태인 경우 (data.userMedical.emrInfo)
    if (data && data.userMedical && data.userMedical.emrInfo && 
        Array.isArray(data.userMedical.emrInfo) && data.userMedical.emrInfo.length > 0) {
      const entry = data.userMedical.emrInfo[0];
      processEntryData(entry);
      return;
    }
    
    console.error('Invalid data structure for populating form:', data);
  };

  // Helper function to process entry data
  const processEntryData = (entry) => {
    console.log('Processing entry data:', entry);
    
    // Populate text areas
    if (entry.reason_visit) document.getElementById('MR1').value = entry.reason_visit;
    if (entry.chief_complaints) document.getElementById('MR2').value = entry.chief_complaints;
    if (entry.specific_cosc) document.getElementById('MR3').value = entry.specific_cosc;
    if (entry.onset) document.getElementById('MR4').value = entry.onset;
    if (entry.social_history) document.getElementById('MR5').value = entry.social_history;
    if (entry.medication) document.getElementById('MR9').value = entry.medication;
    if (entry.allergies) document.getElementById('MR10').value = entry.allergies;
    if (entry.medical_note) document.getElementById('MR11').value = entry.medical_note;
    if (entry.follow_up) document.getElementById('MR12').value = entry.follow_up;
    if (entry.double_check) document.getElementById('MR13').value = entry.double_check;
    
    // Process past_history checkboxes
    if (entry.past_history && Array.isArray(entry.past_history)) {
      console.log('Processing past_history:', entry.past_history);
      
      // Reset all checkboxes first
      document.querySelectorAll('input[name="Past_History"]').forEach(checkbox => {
        checkbox.checked = false;
      });
      
      // Check the appropriate boxes
      entry.past_history.forEach(item => {
        // Handle special case for "Others:" prefix
        if (item.startsWith('Others:')) {
          const otherCheckbox = document.querySelector('input[name="Past_History"][value="Others"]');
          if (otherCheckbox) {
            otherCheckbox.checked = true;
            const otherInput = otherCheckbox.parentElement.querySelector('.PE-checkBoxItemOther');
            if (otherInput) {
              otherInput.value = item.replace('Others:', '').trim();
            }
          }
        } else {
          const checkbox = document.querySelector(`input[name="Past_History"][value="${item}"]`);
          if (checkbox) {
            checkbox.checked = true;
            console.log(`Checked past_history checkbox: ${item}`);
          } else {
            console.warn(`Could not find past_history checkbox for: ${item}`);
          }
        }
      });
    }
    
    // Process family_history checkboxes
    if (entry.family_history && Array.isArray(entry.family_history)) {
      console.log('Processing family_history:', entry.family_history);
      
      // Reset all checkboxes first
      document.querySelectorAll('input[name="Family_History"]').forEach(checkbox => {
        checkbox.checked = false;
      });
      
      // Check the appropriate boxes
      entry.family_history.forEach(item => {
        // Handle special case for "Others:" prefix
        if (item.startsWith('Others:')) {
          const otherCheckbox = document.querySelector('input[name="Family_History"][value="Others"]');
          if (otherCheckbox) {
            otherCheckbox.checked = true;
            const otherInput = otherCheckbox.parentElement.querySelector('.PE-checkBoxItemOther');
            if (otherInput) {
              otherInput.value = item.replace('Others:', '').trim();
            }
          }
        } else {
          const checkbox = document.querySelector(`input[name="Family_History"][value="${item}"]`);
          if (checkbox) {
            checkbox.checked = true;
            console.log(`Checked family_history checkbox: ${item}`);
          } else {
            console.warn(`Could not find family_history checkbox for: ${item}`);
          }
        }
      });
    }
    
    // Process pregnancy possibility for female patients
    if (userCall.userInfo.user_gender === 'female' && 
        entry.pregnancy_possibility && 
        Array.isArray(entry.pregnancy_possibility)) {
      
      console.log('Processing pregnancy_possibility:', entry.pregnancy_possibility);
      
      // Reset all checkboxes first
      document.querySelectorAll('input[name="Possibility_of_Pregnancy"]').forEach(checkbox => {
        checkbox.checked = false;
      });
      
      // Check the appropriate boxes
      entry.pregnancy_possibility.forEach(item => {
        const checkbox = document.querySelector(`input[name="Possibility_of_Pregnancy"][value="${item}"]`);
        if (checkbox) {
          checkbox.checked = true;
        } else {
          console.warn(`Could not find pregnancy_possibility checkbox for: ${item}`);
        }
      });
    }
    
    console.log('Form population complete');
  };

  const save = () => {
    // Get all the form data
    const formData = {
      medical_history_id: chatPrgrs.medical_history_id,
      reasonForVisit: document.getElementById('MR1').value,
      chiefComplaints: document.getElementById('MR2').value,
      specificConcerns: document.getElementById('MR3').value,
      onset: document.getElementById('MR4').value,
      socialHistory: document.getElementById('MR5').value,
      pastHistory: getPastHistoryData(),
      familyHistory: getFamilyHistoryData(),
      pregnancyPossibility: userCall.userInfo.user_gender === 'female' ? getPregnancyData() : null,
      currentMedications: document.getElementById('MR9').value,
      allergies: document.getElementById('MR10').value,
      medicalNote: document.getElementById('MR11').value,
      followUp: document.getElementById('MR12').value,
      doubleCheck: document.getElementById('MR13').value,
      patientInfo: {
        name: `userCall.userInfo.user_firstname` + " " + `userCall.userInfo.user_lastname`,
        age: userCall.userInfo.user_age,
        gender: userCall.userInfo.user_gender,
      }
    };

    // Send the data to your backend
    axios.post(`/PreChat/Save`, formData)
      .then(response => {
        console.log('Data saved successfully:', response.data);
        // Show success notification
      })
      .catch(error => {
        console.error('Error saving pre-examination data:', error);
        // Show error notification
      });
  };

  // Helper function to get all checked past history items
  const getPastHistoryData = () => {
    const pastHistoryItems = document.querySelectorAll('input[name="Past_History"]:checked');
    const pastHistory = Array.from(pastHistoryItems).map(item => item.value);
    
    // "Others" 체크박스가 선택되었는지 확인
    const othersCheckbox = document.querySelector('input[name="Past_History"][value="Others"]:checked');
    if (othersCheckbox) {
      // 체크박스 옆의 텍스트 입력 필드 찾기
      const otherInput = othersCheckbox.parentElement.querySelector('.PE-checkBoxItemOther');
      if (otherInput && otherInput.value.trim()) {
        // "Others" 값 제거하고 실제 텍스트로 대체
        const index = pastHistory.indexOf("Others");
        if (index > -1) {
          pastHistory.splice(index, 1);
        }
        pastHistory.push(`Others: ${otherInput.value.trim()}`);
      }
    }
    
    return pastHistory;
  };
  
  // Helper function to get all checked family history items
  const getFamilyHistoryData = () => {
    const familyHistoryItems = document.querySelectorAll('input[name="Family_History"]:checked');
    const familyHistory = Array.from(familyHistoryItems).map(item => item.value);
    
    // Get "Others" text input if checked
    const othersCheckbox = document.querySelector('input[name="Family_History"][value="Others"]:checked');
    if (othersCheckbox) {
      const otherInput = othersCheckbox.parentElement.querySelector('.PE-checkBoxItemOther');
      if (otherInput && otherInput.value.trim()) {
        // Remove the generic "Others" value and replace with the specific text
        const index = familyHistory.indexOf("Others");
        if (index > -1) {
          familyHistory.splice(index, 1);
        }
        familyHistory.push(`Others: ${otherInput.value.trim()}`);
      }
    }
    
    return familyHistory;
  };

  // Helper function to get pregnancy data (for female patients only)
  const getPregnancyData = () => {
    const pregnancyItems = document.querySelectorAll('input[name="Possibility_of_Pregnancy"]:checked');
    return Array.from(pregnancyItems).map(item => item.value);
  };

  const clickComplete = () => {
    const formData = {
      reasonForVisit: document.getElementById('MR1').value,
      chiefComplaints: document.getElementById('MR2').value,
      specificConcerns: document.getElementById('MR3').value,
      onset: document.getElementById('MR4').value,
      socialHistory: document.getElementById('MR5').value,
      pastHistory: getPastHistoryData(),
      familyHistory: getFamilyHistoryData(),
      pregnancyPossibility: userCall.userInfo.user_gender === 'female' ? getPregnancyData() : null,
      currentMedications: document.getElementById('MR9').value,
      allergies: document.getElementById('MR10').value,
      medicalNote: document.getElementById('MR11').value,
      followUp: document.getElementById('MR12').value,
      doubleCheck: document.getElementById('MR13').value,
      patientInfo: {
        name: `userCall.userInfo.user_firstname` + " " + `userCall.userInfo.user_lastname`,
        age: userCall.userInfo.user_age,
        gender: userCall.userInfo.user_gender,
      }
    };
    openCompleteConsultation(userCall,formData);
  }

  const OpenMacroPannel_1 = () => {
    openMacroPannel_1(!MacroPannel1);
  }

  const OpenMacroPannel_2 = () => {
    openMacroPannel_2(!MacroPannel2);
  }

  const OpenMacroPannel_3 = () => {
    openMacroPannel_3(!MacroPannel3);
  }

  const OpenMacroPannel_4 = () => {
    openMacroPannel_4(!MacroPannel4);
  }

  const OpenMacroPannel_5 = () => {
    openMacroPannel_5(!MacroPannel5);
  }

  const OpenMacroPannel_6 = () => {
    openMacroPannel_6(!MacroPannel6);
  }

  const OpenMacroPannel_7 = () => {
    openMacroPannel_7(!MacroPannel7);
  }

  const OpenMacroPannel_8 = () => {
    openMacroPannel_8(!MacroPannel8);
  }

  const OpenMacroPannel_9 = () => {
    openMacroPannel_9(!MacroPannel9);
  }

  const OpenMacroPannel_10 = () => {
    openMacroPannel_10(!MacroPannel10);
  }

  const OpenMacroPannel_11 = () => {
    openMacroPannel_11(!MacroPannel11);
  }

  const OpenMacroPannel_12 = () => {
    openMacroPannel_12(!MacroPannel12);
  }

  const OpenMacroPannel_13 = () => {
    openMacroPannel_13(!MacroPannel13);
  }

  // 각 매크로 버튼용 핸들러 함수들
  const handleMacroClick_1 = () => {
    console.log("매크로 1 버튼 클릭됨");
    if (typeof setMacroPQ_1 === 'function') {
      setMacroPQ_1();
      openMacroPannel_1(!MacroPannel1);
      console.log("매크로 1 함수 호출 완료");
    } else {
      console.error("매크로 함수가 없거나 함수 타입이 아닙니다:", setMacroPQ_1);
    }
  };

  const handleMacroClick_2 = () => {
    console.log("매크로 2 버튼 클릭됨");
    if (typeof setMacroPQ_2 === 'function') {
      setMacroPQ_2();
      openMacroPannel_2(!MacroPannel2);
      console.log("매크로 2 함수 호출 완료");
    }
  };

  const handleMacroClick_3 = () => {
    console.log("매크로 3 버튼 클릭됨");
    if (typeof setMacroPQ_3 === 'function') {
      setMacroPQ_3();
      openMacroPannel_3(!MacroPannel3);
      console.log("매크로 3 함수 호출 완료");
    }
  };

  const handleMacroClick_4 = () => {
    console.log("매크로 4 버튼 클릭됨");
    if (typeof setMacroPQ_4 === 'function') {
      setMacroPQ_4();
      openMacroPannel_4(!MacroPannel4);
      console.log("매크로 4 함수 호출 완료");
    }
  };

  const handleMacroClick_5 = () => {
    console.log("매크로 5 버튼 클릭됨");
    if (typeof setMacroPQ_5 === 'function') {
      setMacroPQ_5();
      openMacroPannel_5(!MacroPannel5);
      console.log("매크로 5 함수 호출 완료");
    }
  };

  const handleMacroClick_6 = () => {
    console.log("매크로 6 버튼 클릭됨");
    if (typeof setMacroPQ_6 === 'function') {
      setMacroPQ_6();
      openMacroPannel_6(!MacroPannel6);
      console.log("매크로 6 함수 호출 완료");
    }
  };

  const handleMacroClick_7 = () => {
    console.log("매크로 7 버튼 클릭됨");
    if (typeof setMacroPQ_7 === 'function') {
      setMacroPQ_7();
      openMacroPannel_7(!MacroPannel7);
      console.log("매크로 7 함수 호출 완료");
    }
  };

  const handleMacroClick_8 = () => {
    console.log("매크로 8 버튼 클릭됨");
    if (typeof setMacroPQ_8 === 'function') {
      setMacroPQ_8();
      openMacroPannel_8(!MacroPannel8);
      console.log("매크로 8 함수 호출 완료");
    }
  };

  const handleMacroClick_9 = () => {
    console.log("매크로 9 버튼 클릭됨");
    if (typeof setMacroPQ_9 === 'function') {
      setMacroPQ_9();
      openMacroPannel_9(!MacroPannel9);
      console.log("매크로 9 함수 호출 완료");
    }
  };

  const handleMacroClick_10 = () => {
    console.log("매크로 10 버튼 클릭됨");
    if (typeof setMacroPQ_10 === 'function') {
      setMacroPQ_10();
      openMacroPannel_10(!MacroPannel10);
      console.log("매크로 10 함수 호출 완료");
    }
  };

  const handleMacroClick_11 = () => {
    console.log("매크로 11 버튼 클릭됨");
    if (typeof setMacroPQ_11 === 'function') {
      setMacroPQ_11();
      openMacroPannel_11(!MacroPannel11);
      console.log("매크로 11 함수 호출 완료");
    }
  };

  const handleMacroClick_12 = () => {
    console.log("매크로 12 버튼 클릭됨");
    if (typeof setMacroPQ_12 === 'function') {
      setMacroPQ_12();
      openMacroPannel_12(!MacroPannel12);
      console.log("매크로 12 함수 호출 완료");
    }
  };

  const handleMacroClick_13 = () => {
    console.log("매크로 13 버튼 클릭됨");
    if (typeof setMacroPQ_13 === 'function') {
      setMacroPQ_13();
      openMacroPannel_13(!MacroPannel13);
      console.log("매크로 13 함수 호출 완료");
    }
  };

  const handleMacroClickMR_1 = () => {
    const textarea = document.getElementById("MR1");
    textarea.value = "Reason for visit: [General Health Consultation]";
    openMacroPannel_1(!MacroPannel1);
  }
  
  const handleMacroClickMR_2 = () => {
    const textarea = document.getElementById("MR2");
    textarea.value = "Reported symptoms and discomfort areas: [Patient's input]";
    openMacroPannel_2(!MacroPannel2);
  }
  
  const handleMacroClickMR_3 = () => {
    const textarea = document.getElementById("MR3");
    textarea.value = "Patient's specific concerns or suspected conditions: [Patient's input]";
    openMacroPannel_3(!MacroPannel3);
  }
  
  const handleMacroClickMR_4 = () => {
    const textarea = document.getElementById("MR4");
    textarea.value = "Duration of symptoms: [Patient's input]";
    openMacroPannel_4(!MacroPannel4);
  }
  
  const handleMacroClickMR_5 = () => {
    const textarea = document.getElementById("MR5");
    textarea.value = "1. Smoking \n- Status: \n- Frequency:\n\n2. Alcohol Consumption \n- Status: \n- Frequency:\n\n3. Exercise \n- Frequency:\n\n4. Occupation \n- Information:"
    openMacroPannel_5(!MacroPannel5);
  }
  
  const handleMacroClickMR_6 = () => {
    const textarea = document.getElementById("MR6");
    textarea.value = "Past or current medical conditions: [Selected conditions]";
    openMacroPannel_6(!MacroPannel6);
  }
  
  const handleMacroClickMR_7 = () => {
    const textarea = document.getElementById("MR7");
    textarea.value = "Family medical history: [Selected conditions]";
    openMacroPannel_7(!MacroPannel7);
  }
  
  const handleMacroClickMR_8 = () => {
    const textarea = document.getElementById("MR8");
    textarea.value = "";
    openMacroPannel_8(!MacroPannel8);
  }
  
  const handleMacroClickMR_9 = () => {
    const textarea = document.getElementById("MR9");
    textarea.value = "Current medications and supplements: [Patient's input]";
    openMacroPannel_9(!MacroPannel9);
  }
  
  const handleMacroClickMR_10 = () => {
    const textarea = document.getElementById("MR10");
    textarea.value = "Current patient allergies: [Patient's input]";
    openMacroPannel_10(!MacroPannel10);
  }
  
  const handleMacroClickMR_11 = () => {
    const textarea = document.getElementById("MR11");
    textarea.value = "";
    openMacroPannel_11(!MacroPannel11);
  }
  
  const handleMacroClickMR_12 = () => {
    const textarea = document.getElementById("MR12");
    textarea.value = "";
    openMacroPannel_12(!MacroPannel12);
  }
  
  const handleMacroClickMR_13 = () => {
    const textarea = document.getElementById("MR13");
    textarea.value = "";
    openMacroPannel_13(!MacroPannel13);
  }

  if (!userCall) {
    return <div>Loading...</div>;
  }

  const {
    selectMedicalDep,
    writeSymptom,
    userName,
    age,
    userMedication,
    gender,
    userAdrugeffect,
    userMedicalhistory,
    iconImage,
    user_etc
  } = userCall;

  return (
    <div className="PatientInfo_div">
      <div className="PatientInfo_div-2">
        <div className="PE_combined-content">
          <div className='PE-Title'>Patient Demographics</div>
          <div className='PE-infoContainer'>
            <div className='PE-PatientPersonalInfoContainer'>
              <div className='PE-NameAgeGender'><span className='PE-Name'> 
                {userCall.userInfo.user_firstname + " " + userCall.userInfo.user_lastname}
                </span><span className='PE-AgeGender'>({userCall.userInfo.user_age}, {userCall.userInfo.user_gender})</span></div>
              <div className='.PE-Number'>{userCall.user.user_phonenumber}</div>
            </div>
            <div className='PE-Sub'>

              <div className='PE-SubContainer'>
                <div className='PE-SubTitleContainer'>
                  <span className='PE-SubTItle'>Reason for Visit</span>
                  <button className='PE-MacroBtn' onClick={OpenMacroPannel_1}>
                    <img src="./icons/web_icon/Btn_AddMecro.png" alt="" />
                  </button>
                </div>

                {MacroPannel1 && (
                  <div className='PE-MacroContainerRelative'>
                    <div className='PE-MacroContainer'>
                      <button className='PE-MacroPQ' onClick={handleMacroClick_1}>
                        <span className='PE-MacroString'>Auto-complete for </span>
                        <span className='PE-MacroType1'>Patient Questions</span>
                      </button>
                      <button className='PE-MacroMR' onClick={handleMacroClickMR_1}><span className='PE-MacroString'>Auto-complete for </span> <span className='PE-MacroType2'>Medical Records</span></button>
                    </div>
                  </div>
                )}

                <textarea className='PE-ContentBox PE-h95' id='MR1'></textarea>
              </div>

              <div className='PE-SubContainer'>
                <div className='PE-SubTitleContainer'>
                  <span className='PE-SubTItle'>Chief Complaints</span>
                  <button className='PE-MacroBtn' onClick={OpenMacroPannel_2}>
                    <img src="./icons/web_icon/Btn_AddMecro.png" alt="" />
                  </button>
                </div>

                {MacroPannel2 && (
                  <div className='PE-MacroContainerRelative'>
                    <div className='PE-MacroContainer'>
                      <button className='PE-MacroPQ' onClick={handleMacroClick_2}>
                        <span className='PE-MacroString'>Auto-complete for</span> 
                        <span className='PE-MacroType1'>Patient Questions</span>
                      </button>
                      <button className='PE-MacroMR' onClick={handleMacroClickMR_2}><span className='PE-MacroString'>Auto-complete for </span> <span className='PE-MacroType2'>Medical Records</span></button>
                    </div>
                  </div>
                )}

                <textarea className='PE-ContentBox PE-h70' id='MR2'></textarea>
              </div>

              <div className='PE-SubContainer'>
                <div>
                  <span className='PE-SubTItle'>Specific Concerns or </span>
                  <div className='PE-SubTitleHolder'>
                  <span className='PE-SubTItle'>Suspected Conditional</span>
                    <button className='PE-MacroBtn' onClick={OpenMacroPannel_3}>
                      <img src="./icons/web_icon/Btn_AddMecro.png" alt="" />
                    </button>
                  </div>
                </div>

                {MacroPannel3 && (
                  <div className='PE-MacroContainerRelative'>
                    <div className='PE-MacroContainer'>
                      <button className='PE-MacroPQ' onClick={handleMacroClick_3}>
                        <span className='PE-MacroString'>Auto-complete for</span> 
                        <span className='PE-MacroType1'>Patient Questions</span>
                      </button>
                      <button className='PE-MacroMR' onClick={handleMacroClickMR_3}><span className='PE-MacroString'>Auto-complete for </span> <span className='PE-MacroType2'>Medical Records</span></button>
                    </div>
                  </div>
                )}

                <textarea className='PE-ContentBox PE-h70' id='MR3'></textarea>
              </div>

              <div className='PE-SubContainer'>
                <div className='PE-SubTitleContainer'>
                  <span className='PE-SubTItle'>Onset</span>
                  <button className='PE-MacroBtn' onClick={OpenMacroPannel_4}>
                    <img src="./icons/web_icon/Btn_AddMecro.png" alt="" />
                  </button>
                </div>

                {MacroPannel4 && (
                  <div className='PE-MacroContainerRelative'>
                    <div className='PE-MacroContainer'>
                      <button className='PE-MacroPQ' onClick={handleMacroClick_4}>
                        <span className='PE-MacroString'>Auto-complete for</span> 
                        <span className='PE-MacroType1'>Patient Questions</span>
                      </button>
                      <button className='PE-MacroMR' onClick={handleMacroClickMR_4}><span className='PE-MacroString'>Auto-complete for </span> <span className='PE-MacroType2'>Medical Records</span></button>
                    </div>
                  </div>
                )}

                <textarea className='PE-ContentBox PE-h65' id='MR4'></textarea>
              </div>

              <div className='PE-SubContainer'>
                <div className='PE-SubTitleContainer'>
                  <span className='PE-SubTItle'>Social History</span>
                  <button className='PE-MacroBtn' onClick={OpenMacroPannel_5}>
                    <img src="./icons/web_icon/Btn_AddMecro.png" alt="" />
                  </button>
                </div>

                {MacroPannel5 && (
                  <div className='PE-MacroContainerRelative'>
                    <div className='PE-MacroContainer'>
                      <button className='PE-MacroPQ' onClick={handleMacroClick_5}>
                        <span className='PE-MacroString'>Auto-complete for</span> 
                        <span className='PE-MacroType1'>Patient Questions</span>
                      </button>
                      <button className='PE-MacroMR' onClick={handleMacroClickMR_5}><span className='PE-MacroString'>Auto-complete for </span> <span className='PE-MacroType2'>Medical Records</span></button>
                    </div>
                  </div>
                )}

                <textarea className='PE-ContentBox PE-h70' id='MR5'></textarea>
              </div>

              {/* Past History Section */}
              <div className='PE-SubContainer'>
                <div className='PE-SubTitleContainer'>
                  <span className='PE-SubTItle'>Past History</span>
                  <button className='PE-MacroBtn' onClick={OpenMacroPannel_6}>
                    <img src="./icons/web_icon/Btn_AddMecro.png" alt="" />
                  </button>
                </div>

                {MacroPannel6 && (
                  <div className='PE-MacroContainerRelative'>
                    <div className='PE-MacroContainer'>
                      <button className='PE-MacroPQ' onClick={handleMacroClick_6}>
                        <span className='PE-MacroString'>Auto-complete for</span> 
                        <span className='PE-MacroType1'>Patient Questions</span>
                      </button>
                    </div>
                  </div>
                )}

                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Past_History" value="Diabetes_mellitus" />
                  <label>Diabetes mellitus</label>
                </div>
                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Past_History" value="Hypertension" />
                  <label>Hypertension</label>
                </div>
                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Past_History" value="Chronic_obstructive_pulmonary_disease" />
                  <label>Chronic obstructive pulmonary disease</label>
                </div>
                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Past_History" value="Heart_problems" />
                  <label>Heart problems</label>
                </div>
                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Past_History" value="Stroke" />
                  <label>Stroke</label>
                </div>
                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Past_History" value="Chronic_kidney_disease" />
                  <label>Chronic kidney disease</label>
                </div>
                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Past_History" value="Illicit_drug_use" />
                  <label>Illicit drug use</label>
                </div>
                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Past_History" value="Alcohol_dependence" />
                  <label>Alcohol dependence</label>
                </div>
                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Past_History" value="AIDS" />
                  <label>AIDS</label>
                </div>
                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Past_History" value="Dementia" />
                  <label>Dementia</label>
                </div>
                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Past_History" value="Others" />
                  <label>Others</label>
                  <input type="text" className='PE-checkBoxItemOther' />
                </div>
              </div>

              {/* Family History Section */}
              <div className='PE-SubContainer'>
                <div className='PE-SubTitleContainer'>
                  <span className='PE-SubTItle'>Family History</span>
                  <button className='PE-MacroBtn' onClick={OpenMacroPannel_7}>
                    <img src="./icons/web_icon/Btn_AddMecro.png" alt="" />
                  </button>
                </div>

                {MacroPannel7 && (
                  <div className='PE-MacroContainerRelative'>
                    <div className='PE-MacroContainer'>
                      <button className='PE-MacroPQ' onClick={handleMacroClick_7}>
                        <span className='PE-MacroString'>Auto-complete for</span> 
                        <span className='PE-MacroType1'>Patient Questions</span>
                      </button>
                    </div>
                  </div>
                )}

                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Family_History" value="Diabetes_mellitus" />
                  <label>Diabetes mellitus</label>
                </div>
                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Family_History" value="Hypertension" />
                  <label>Hypertension</label>
                </div>
                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Family_History" value="Chronic_obstructive_pulmonary_disease" />
                  <label>Chronic obstructive pulmonary disease</label>
                </div>
                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Family_History" value="Heart_problems" />
                  <label>Heart problems</label>
                </div>
                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Family_History" value="Stroke" />
                  <label>Stroke</label>
                </div>
                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Family_History" value="Chronic_kidney_disease" />
                  <label>Chronic kidney disease</label>
                </div>
                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Family_History" value="Dementia" />
                  <label>Dementia</label>
                </div>
                <div className='PE-CheckBoxItemContainer'>
                  <input type="checkbox" name="Family_History" value="Others" />
                  <label>Others</label>
                  <input type="text" className='PE-checkBoxItemOther' />
                </div>
              </div>

              {/* Pregnancy Possibility Section (for female patients only) */}
              {userCall.userInfo.user_gender === 'female' && (
                <div className='PE-SubContainer'>
                  <div>
                    <span className='PE-SubTItle'>Possibility of Pregnancy</span>
                    <div className='PE-SubTitleHolder'>
                      <span className='PE-SubTItle'>(For Female Patient)</span>
                      <button className='PE-MacroBtn' onClick={OpenMacroPannel_8}>
                        <img src="./icons/web_icon/Btn_AddMecro.png" alt="" />
                      </button>
                    </div>
                  </div>

                  {MacroPannel8 && (
                    <div className='PE-MacroContainerRelative'>
                      <div className='PE-MacroContainer'>
                        <button className='PE-MacroPQ' onClick={handleMacroClick_8}>
                          <span className='PE-MacroString'>Auto-complete for</span> 
                          <span className='PE-MacroType1'>Patient Questions</span>
                        </button>
                      </div>
                    </div>
                  )}

                  <div className='PE-CheckBoxItemContainer'>
                    <input type="checkbox" name="Possibility_of_Pregnancy" value="Pregnancy_possible" />
                    <label>Pregnancy possible</label>
                  </div>
                </div>
              )}

              <div className='PE-SubContainer'>
                <div className='PE-SubTitleContainer'>
                  <span className='PE-SubTItle'>Current Medications and Supplements</span>
                  <button className='PE-MacroBtn' onClick={OpenMacroPannel_9}>
                    <img src="./icons/web_icon/Btn_AddMecro.png" alt="" />
                  </button>
                </div>

                {MacroPannel9 && (
                  <div className='PE-MacroContainerRelative'>
                    <div className='PE-MacroContainer'>
                      <button className='PE-MacroPQ' onClick={handleMacroClick_9}>
                        <span className='PE-MacroString'>Auto-complete for</span> 
                        <span className='PE-MacroType1'>Patient Questions</span>
                      </button>
                      <button className='PE-MacroMR' onClick={handleMacroClickMR_9}><span className='PE-MacroString'>Auto-complete for </span> <span className='PE-MacroType2'>Medical Records</span></button>
                    </div>
                  </div>
                )}

                <textarea className='PE-ContentBox PE-h70' id='MR9'></textarea>
              </div>

              <div className='PE-SubContainer'>
                <div className='PE-SubTitleContainer'>
                  <span className='PE-SubTItle'>Allergies</span>
                  <button className='PE-MacroBtn' onClick={OpenMacroPannel_10}>
                    <img src="./icons/web_icon/Btn_AddMecro.png" alt="" />
                  </button>
                </div>

                {MacroPannel10 && (
                  <div className='PE-MacroContainerRelative'>
                    <div className='PE-MacroContainer'>
                      <button className='PE-MacroPQ' onClick={handleMacroClick_10}>
                        <span className='PE-MacroString'>Auto-complete for</span> 
                        <span className='PE-MacroType1'>Patient Questions</span>
                      </button>
                      <button className='PE-MacroMR' onClick={handleMacroClickMR_10}><span className='PE-MacroString'>Auto-complete for </span> <span className='PE-MacroType2'>Medical Records</span></button>
                    </div>
                  </div>
                )}

                <textarea className='PE-ContentBox PE-h70' id='MR10'></textarea>
              </div>

              <div className='PE-SubContainer'>
                <div className='PE-SubTitleContainer'>
                  <span className='PE-SubTItle'>Medical Note</span>
                </div>

                {MacroPannel11 && (
                  <div className='PE-MacroContainerRelative'>
                    <div className='PE-MacroContainer'>
                      <button className='PE-MacroPQ' onClick={handleMacroClick_11}>
                        <span className='PE-MacroString'>Auto-complete for</span> 
                        <span className='PE-MacroType1'>Patient Questions</span>
                      </button>
                      <button className='PE-MacroMR' onClick={handleMacroClickMR_11}><span className='PE-MacroString'>Auto-complete for </span> <span className='PE-MacroType2'>Medical Records</span></button>
                    </div>
                  </div>
                )}

                <textarea className='PE-ContentBox PE-h70' id='MR11'></textarea>
              </div>

              <div className='PE-SubContainer'>
                <div className='PE-SubTitleContainer'>
                  <span className='PE-SubTItle'>Follow Up</span>
                </div>

                {MacroPannel12 && (
                  <div className='PE-MacroContainerRelative'>
                    <div className='PE-MacroContainer'>
                      <button className='PE-MacroPQ' onClick={handleMacroClick_12}>
                        <span className='PE-MacroString'>Auto-complete for</span> 
                        <span className='PE-MacroType1'>Patient Questions</span>
                      </button>
                      <button className='PE-MacroMR' onClick={handleMacroClickMR_12}><span className='PE-MacroString'>Auto-complete for </span> <span className='PE-MacroType2'>Medical Records</span></button>
                    </div>
                  </div>
                )}

                <textarea className='PE-ContentBox PE-h70' id='MR12'></textarea>
              </div>

              <div className='PE-SubContainer'>
                <div className='PE-SubTitleContainer'>
                  <span className='PE-SubTItle'>Double-Check Before You Treat</span>
                </div>

                {MacroPannel13 && (
                  <div className='PE-MacroContainerRelative'>
                    <div className='PE-MacroContainer'>
                      <button className='PE-MacroPQ' onClick={handleMacroClick_13}>
                        <span className='PE-MacroString'>Auto-complete for</span> 
                        <span className='PE-MacroType1'>Patient Questions</span>
                      </button>
                      <button className='PE-MacroMR' onClick={handleMacroClickMR_13}><span className='PE-MacroString'>Auto-complete for </span> <span className='PE-MacroType2'>Medical Records</span></button>
                    </div>
                  </div>
                )}

                <textarea className='PE-ContentBox PE-h70' id='MR13'></textarea>
              </div>

            </div>

          </div>
          <div className='PE-BtnContainer'>
            <button className='PE-Btn PE-BtnSave' onClick={save}>Save</button>
            <button className='PE-Btn PE-BtnComplete' onClick={clickComplete}>Complete Pre-consultation</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreExamination;