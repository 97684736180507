import React from 'react';
import '../css/ProgressBars.css';

const ProgressBars = ({ currentStep, totalSteps, onBackClick, backIcon }) => {
  return (
    <div className="progress-container">
      {currentStep > 1 && currentStep < 7 && (
        <img 
          src={backIcon} 
          alt="Go back" 
          className="back-arrow" 
          onClick={onBackClick}
        />
      )}
      {[...Array(totalSteps)].map((_, index) => (
        <div
          key={index}
          className={`progress-bar ${currentStep > index ? 'active' : ''}`}
        />
      ))}
    </div>
  );
  
};
 
export default ProgressBars;