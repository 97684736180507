import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/public.css';
import '../css/Login.css';

const IP = process.env.REACT_APP_HOST;

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loginError, setLoginError] = useState('');
    const [isEmailFocused, setIsEmailFocused] = useState(false);
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [activeTab, setActiveTab] = useState('doctor'); // 'doctor' or 'precounselor'

    const navigate = useNavigate();

    useEffect(() => {
        fillForm();
    }, []);

    const validateEmail = (email) => {
        if (!email) {
            return { isValid: false, message: 'Please enter your email.' };
        }

        const re = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        return {
            isValid: re.test(String(email).toLowerCase()),
            message: re.test(String(email).toLowerCase()) ? '' : 'Please check your email'
        };
    };

    const handleEmailFocus = () => {
        setIsEmailFocused(true);
        setEmailError('');
        setLoginError('');
    };

    const handlePasswordFocus = () => {
        setIsPasswordFocused(true);
        setPasswordError('');
        setLoginError('');
    };

    const validatePassword = (password) => {
        if (!password) {
            return { isValid: false, message: 'Please enter your password.' };
        }

        const re = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()\-_=+\\|[\]{};:\'",.<>\/?]).{8,15}$/;
        return {
            isValid: re.test(password),
            message: re.test(password) ? '' : 'Please check your password'
        };
    };

    const validateForm = () => {
        const emailValidation = validateEmail(email);
        const passwordValidation = validatePassword(password);

        setEmailError(emailValidation.message);
        setPasswordError(passwordValidation.message);

        return emailValidation.isValid && passwordValidation.isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        const isValid = validateForm();

        if (isValid) {
            try {
                // Check if login is for doctor or pre-counselor
                if (activeTab === 'doctor') {
                    // First, check if the email exists
                    console.log("Try Login DrEmail: ", email);
                    const emailCheckResponse = await axios.post(`/drEmailCheck`, { email }, {
                        withCredentials: true
                    });
                    if (emailCheckResponse.data.success) {
                        // Email doesn't exist
                        setEmailError("User not found. Please check your email or sign up");
                        setLoginError('');
                    } else {
                        // Email exists, proceed with login
                        const loginResponse = await axios.post(`/Login`, {
                            email,
                            password,
                            rememberMe
                        }, {
                            withCredentials: true
                        });
                        localStorage.setItem('email', email);
                        console.log(loginResponse);

                        if (loginResponse.status === 200) {
                            handleRememberMe();
                            navigate('/WebChat');                            
                        }
                    }
                } else {
                    // Pre-counselor login flow
                    // Adjust these endpoints as needed for your backend
                    //const loginResponse = await axios.post(`/PreCounselorLogin`, {
                    console.log("Try Login DrEmail: ", email);
                    const loginResponse = await axios.post(`/PreLogin`, {
                        email,
                        password,
                        rememberMe
                    }, {
                        withCredentials: true
                    });

                    localStorage.setItem('email', email);

                    if (loginResponse.status === 200) {
                        handleRememberMe();
                        navigate('/PreWebChatPage')
                    }
                }
            } catch (error) {
                console.error('Login error:', error);
                if (error.response) {
                    if (error.response.status === 401) {
                        setEmailError("User not found. Please check your email or sign up");
                        setPasswordError('');
                    } else if (error.response.status === 402) {
                        setEmailError(activeTab === 'doctor'
                            ? "The doctor's license is not verified"
                            : "The pre-counselor's account is not verified");
                        setPasswordError('');
                    } else if (error.response.status === 403) {
                        setEmailError("This account is already logged in from another device");
                        setPasswordError('');
                    } else {
                        setLoginError('An error occurred. Please try again later.');
                    }
                } else {
                    setLoginError('An error occurred. Please try again later.');
                }
            }
        } else {
            setLoginError('');
        }
    };

    const handleClearEmail = () => {
        setEmail('');
        setEmailError('');
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleRememberMe = () => {
        if (rememberMe) {
            localStorage.setItem('email', email);
            localStorage.setItem('rememberMe', 'true');
            localStorage.setItem('activeTab', activeTab);
        } else {
            // localStorage.removeItem('email');
            localStorage.removeItem('rememberMe');
            localStorage.setItem('activeTab', activeTab);
        }
    };

    const fillForm = () => {
        const savedEmail = localStorage.getItem('email');
        const savedRememberMe = localStorage.getItem('rememberMe');
        const savedTab = localStorage.getItem('activeTab');

        if (savedTab && (savedTab === 'doctor' || savedTab === 'precounselor')) {
            setActiveTab(savedTab);
        }
    
        if (savedEmail && savedRememberMe === 'true') {
            setEmail(savedEmail);
            setRememberMe(true);
        } else {
            setEmail('');
            setRememberMe(false);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (isSubmitted) {
            setEmailError('');
            setIsSubmitted(false);
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (isSubmitted) {
            setPasswordError('');
            setEmailError('');
            setIsSubmitted(false);
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        // Clear any existing error messages and input data
        setEmailError('');
        setPasswordError('');
        setLoginError('');
        setIsSubmitted(false);
        // Clear input field values
        setEmail('');
        setPassword('');
        setShowPassword(false);
    };

    return (
        <div className="login-page-container">
            <div className="login-header">
                <img src="/icons/web_icon/Logo.png" alt="MediKnocks Logo" className="logo-image" />
            </div>
            <div className="login-content">
                <div className="login-card">
                    <div className="login-tabs">
                        <button
                            className={`tab-button-do ${activeTab === 'doctor' ? 'active-do' : ''}`}
                            onClick={() => handleTabChange('doctor')}
                        >
                            Doctor
                        </button>
                        <button
                            className={`tab-button-re ${activeTab === 'precounselor' ? 'active-re' : ''}`}
                            onClick={() => handleTabChange('precounselor')}
                        >
                            Pre-examination Specialist
                        </button>
                    </div>

                    <div className='shadowContainer'>

                        <div className="login-welcome">
                            <div className="welcome-icon">
                                {activeTab === 'doctor' ? <img src="/icons/web_icon/Login_welcome.png" alt="Welcome" /> : <img src="/icons/web_icon/Login_welcomepre.png" alt="Welcome" />}
                            </div>
                            <p className="welcome-subtext">Please sign into your account</p>
                        </div>

                        <form onSubmit={handleSubmit} className="login-form">
                            <div className={`input-field ${isEmailFocused ? 'focused' : ''} ${emailError ? 'error' : ''}`}>
                                <span className="input-icon">
                                    <i className="email-icon"></i>
                                </span>
                                <input
                                    type="text"
                                    placeholder="Email Address"
                                    value={email}
                                    onChange={handleEmailChange}
                                    onFocus={handleEmailFocus}
                                    onBlur={() => setIsEmailFocused(false)}
                                />
                                {email && (
                                            <button type="button" className="btnClear" onClick={handleClearEmail}>
                                                ×
                                            </button>
                                        )}
                                {emailError && <div className="error-message">{emailError}</div>}
                            </div>

                            <div className={`input-field ${isPasswordFocused ? 'focused' : ''} ${passwordError ? 'error' : ''}`}>
                                <span className="input-icon">
                                    <i className="password-icon"></i>
                                </span>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    onFocus={handlePasswordFocus}
                                    onBlur={() => setIsPasswordFocused(false)}
                                />
                                {password && (
                                    <button
                                        type="button"
                                        className="toggle-password"
                                        onClick={handleTogglePassword}
                                    >
                                        <img style={{width:'40px'}}
                                            src={showPassword ? "/icons/web_icon/img_hiddenPassword.png" : "/icons/web_icon/img_showPassword.png"}
                                            alt={showPassword ? "Hide password" : "Show password"}
                                            className="password-toggle-icon"
                                        />
                                    </button>
                                )}
                                {passwordError && <div className="error-message">{passwordError}</div>}
                            </div>

                            <div className="form-options">
                                <label className="remember-option">
                                    <input
                                        type="checkbox"
                                        checked={rememberMe}
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                    />
                                    <span className="checkbox-custom"></span>
                                    <span>Remember me</span>
                                </label>
                                <button
                                    type="button"
                                    className="forgot-password-link"
                                    onClick={() => navigate('/ResetPassword')}
                                >
                                    Forgot Password?
                                </button>
                            </div>

                            <button type="submit" className="login-button">
                                Log in
                            </button>

                            {loginError && <div className="login-error">{loginError}</div>}
                        </form>

                        {activeTab === 'doctor' ?
                            <div className="signup-prompt">
                                Don't have an account?
                                <button
                                    type="button"
                                    className="signup-link"
                                    onClick={() => navigate('/SignUp')}
                                >
                                    Sign up
                                </button>
                            </div>
                            :
                            <div className="signup-prompt">
                                <button className='signup-link'></button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;