import React, { useState, useRef, useEffect } from 'react';
import '../css/FilteringComponent.css';

const FilteringComponent = ({ isOpen, onClose, onApply }) => {
  const [expandedSections, setExpandedSections] = useState({
    gender: false,
    preExamination: false,
    department: false
  });

  const [filters, setFilters] = useState({
    gender: 'all',
    preExamination: 'all',
    department: []
  });

  // Toggle section expanded state
  const toggleSection = (section) => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section]
    });
  };

  // Handle radio button changes
  const handleRadioChange = (filterType, value) => {
    setFilters({
      ...filters,
      [filterType]: value
    });
  };

  // Handle checkbox changes
  const handleCheckboxChange = (value) => {
    const currentDepartments = [...filters.department];
    
    if (currentDepartments.includes(value)) {
      setFilters({
        ...filters,
        department: currentDepartments.filter(dept => dept !== value)
      });
    } else {
      setFilters({
        ...filters,
        department: [...currentDepartments, value]
      });
    }
  };

  // Clear all filters
  const clearFilters = () => {
    setFilters({
      gender: 'all',
      preExamination: 'all',
      department: []
    });
  };

  // Apply filters and close
  const handleApply = () => {
    onApply(filters);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="filter-container">
      <div className="filter-header">
        <h2 className="filter-title">Filtering</h2>
        <button className="clear-button" onClick={clearFilters}>clear</button>
      </div>

      {/* Gender Filter */}
      <div className="filter-section">
        <div className="filter-section-header" onClick={() => toggleSection('gender')}>
          <span className="section-title">Gender</span>
          <svg 
            className={`arrow-icon ${expandedSections.gender ? 'expanded' : ''}`}
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <path d={expandedSections.gender ? "M18 15l-6-6-6 6" : "M6 9l6 6 6-6"} />
          </svg>
        </div>
        
        <div className={`filter-options ${expandedSections.gender ? 'expanded' : ''}`}>
          <div className="radio-option">
            <input
              type="radio"
              id="gender-all"
              name="gender"
              value="all"
              checked={filters.gender === 'all'}
              onChange={() => handleRadioChange('gender', 'all')}
              className="radio-input"
            />
            <label htmlFor="gender-all" className="option-label">All</label>
          </div>
          <div className="radio-option">
            <input
              type="radio"
              id="gender-male"
              name="gender"
              value="male"
              checked={filters.gender === 'male'}
              onChange={() => handleRadioChange('gender', 'male')}
              className="radio-input"
            />
            <label htmlFor="gender-male" className="option-label">Male</label>
          </div>
          <div className="radio-option">
            <input
              type="radio"
              id="gender-female"
              name="gender"
              value="female"
              checked={filters.gender === 'female'}
              onChange={() => handleRadioChange('gender', 'female')}
              className="radio-input"
            />
            <label htmlFor="gender-female" className="option-label">Female</label>
          </div>
        </div>
      </div>

      {/* Pre-examination Filter */}
      <div className="filter-section">
        <div className="filter-section-header" onClick={() => toggleSection('preExamination')}>
          <span className="section-title">Completed Pre-examination</span>
          <svg 
            className={`arrow-icon ${expandedSections.preExamination ? 'expanded' : ''}`}
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <path d={expandedSections.preExamination ? "M18 15l-6-6-6 6" : "M6 9l6 6 6-6"} />
          </svg>
        </div>
        
        <div className={`filter-options ${expandedSections.preExamination ? 'expanded' : ''}`}>
          <div className="radio-option">
            <input
              type="radio"
              id="pre-exam-all"
              name="preExamination"
              value="all"
              checked={filters.preExamination === 'all'}
              onChange={() => handleRadioChange('preExamination', 'all')}
              className="radio-input"
            />
            <label htmlFor="pre-exam-all" className="option-label">All</label>
          </div>
          <div className="radio-option">
            <input
              type="radio"
              id="pre-exam-actual"
              name="preExamination"
              value="actual"
              checked={filters.preExamination === 'actual'}
              onChange={() => handleRadioChange('preExamination', 'actual')}
              className="radio-input"
            />
            <label htmlFor="pre-exam-actual" className="option-label">
              Actual Consultation
              <span className="status-indicator green"></span>
            </label>
          </div>
          <div className="radio-option">
            <input
              type="radio"
              id="pre-exam-only"
              name="preExamination"
              value="only"
              checked={filters.preExamination === 'only'}
              onChange={() => handleRadioChange('preExamination', 'only')}
              className="radio-input"
            />
            <label htmlFor="pre-exam-only" className="option-label">
              Pre-examination Only
              <span className="status-indicator red"></span>
            </label>
          </div>
        </div>
      </div>

      {/* Department Assignment Filter */}
      <div className="filter-section">
        <div className="filter-section-header" onClick={() => toggleSection('department')}>
          <span className="section-title">Department Assignment</span>
          <svg 
            className={`arrow-icon ${expandedSections.department ? 'expanded' : ''}`}
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <path d={expandedSections.department ? "M18 15l-6-6-6 6" : "M6 9l6 6 6-6"} />
          </svg>
        </div>
        
        <div className={`filter-options ${expandedSections.department ? 'expanded' : ''}`}>
          <div className="radio-option">
            <input
              type="checkbox"
              id="dept-all-medical"
              name="department"
              value="allMedical"
              checked={filters.department.includes('allMedical')}
              onChange={() => handleCheckboxChange('allMedical')}
              className="checkbox-input"
            />
            <label htmlFor="dept-all-medical" className="option-label">All Medical Department</label>
          </div>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="button-container">
        <button className="FC_cancel-button" onClick={onClose}>Cancel</button>
        <button className="FC_ok-button" onClick={handleApply}>OK</button>
      </div>
    </div>
  );
};

export default FilteringComponent;