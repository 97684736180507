import '../css/PreConsultation.css';

function PreConsultation({ formData, drName, drInfo, btnCancle, btnNext }) {
  // Safely access formData with fallbacks to avoid errors if properties are undefined
  const {
    reasonForVisit = '',
    chiefComplaints = '',
    specificConcerns = '',
    onset = '',
    socialHistory = '',
    pastHistory = [],
    familyHistory = [],
    pregnancyPossibility = [],
    currentMedications = '',
    allergies = '',
    medicalNote = '',
    followUp = '',
    doubleCheck = '',
    patientInfo = {}
  } = formData || {};

  function addZero(date) {
    if (date < 10) {
      const zeroDate = ('00' + date).slice(-2);
      return zeroDate;
    }
    return date;
  }
  const date = new Date();
  const formatDate = `${addZero(date.getDay())}-${addZero(date.getMonth())}-${date.getFullYear()}`;
  const formatClock = `${addZero(date.getHours())}:${addZero(date.getMinutes())}`;

  // Format arrays for display
  const formatArrayData = (array) => {
    if (!array || !Array.isArray(array) || array.length === 0) {
      return 'None';
    }

    // Filter out empty or 'None' values
    const filteredArray = array.filter(item =>
      item && item !== 'None' && item !== 'Not provided');

    if (filteredArray.length === 0) {
      return 'None';
    }

    // Format special items like "Others: [text]" to display nicely
    return filteredArray.map(item => {
      // Add line breaks before Others entries for better readability
      if (item.startsWith('Others:')) {
        return `\n${item}`;
      }
      return item;
    }).join(', ');
  };



  return (
    <div className="PSCR-Prescription">
      <div className='PSCR-Title'>Pre-Consultation</div>
      <div className='PSCR-ScrollArea'>
        <div className='PSCR-SubTitle'>Patient</div>
        <div className='PSCR-SubContainer'>

          <div className='PSCR-ContentsText Util-LeftPadding-15px'> {drName} (40, male)</div>
          <div className='PSCR-ContentsText Util-LeftPadding-15px'><span className='PSCR-ContentsText Gray'>Phone Number:</span> 070-123-4567</div>
        </div>
        <div className='PSCR-SubTitle Util-TopPadding-10px'>Pre-Consultation</div>
        <div className='PSCR-ContentsText'>Reason for Visit</div>
        {reasonForVisit && reasonForVisit !== 'None' && reasonForVisit !== 'Not provided' && (
          <textarea className='PSCR-ContentTextArea'>
            {reasonForVisit}
          </textarea>
        )}
        <div className='PSCR-ContentsText'>Chief Complaints</div>
        {chiefComplaints && chiefComplaints !== 'None' && chiefComplaints !== 'Not provided' && (
          <textarea className='PSCR-ContentTextArea'>
            {chiefComplaints}
          </textarea>
        )}
        <div className='PSCR-ContentsText'>Specific Concerns</div>
        {specificConcerns && specificConcerns !== 'None' && specificConcerns !== 'Not provided' && (
          <textarea className='PSCR-ContentTextArea'>
            {specificConcerns}
          </textarea>
        )}
        <div className='PSCR-ContentsText'>Onset</div>
        {onset && onset !== 'None' && onset !== 'Not provided' && (
          <textarea className='PSCR-ContentTextArea'>
            {onset}
          </textarea>
        )}
        <div className='PSCR-ContentsText'>Social History</div>
        {socialHistory && socialHistory !== 'None' && socialHistory !== 'Not provided' && (
          <textarea className='PSCR-ContentTextArea'>
            {socialHistory}
          </textarea>
        )}
        <div className='PSCR-ContentsText'>Past History</div>
        {pastHistory && pastHistory.length > 0 && formatArrayData(pastHistory) !== 'None' && (
          <textarea className='PSCR-ContentTextArea'>
            {formatArrayData(pastHistory)}
          </textarea>
        )}
        <div className='PSCR-ContentsText'>Family History</div>
        {familyHistory && familyHistory.length > 0 && formatArrayData(familyHistory) !== 'None' && (
          <textarea className='PSCR-ContentTextArea'>
            {formatArrayData(familyHistory)}
          </textarea>
        )}

        {pregnancyPossibility && pregnancyPossibility.length > 0 && formatArrayData(pregnancyPossibility) !== 'None' && (
          <textarea className='PSCR-ContentTextArea'>
            <div className='PSCR-ContentsText'>Pregnancy Possibility</div>
            {formatArrayData(pregnancyPossibility)}
          </textarea>
        )}
        <div className='PSCR-ContentsText'>Current Medications and Supplements</div>
        {currentMedications && currentMedications !== 'None' && currentMedications !== 'Not provided' && (
          <textarea className='PSCR-ContentTextArea'>
            {currentMedications}
          </textarea>
        )}
        <div className='PSCR-ContentsText'>Allergies</div>
        {allergies && allergies !== 'None' && allergies !== 'Not provided' && (
          <textarea className='PSCR-ContentTextArea'>
            {allergies}
          </textarea>
        )}
        <div className='PSCR-ContentsText'>Medical Note</div>
        {medicalNote && medicalNote !== 'None' && medicalNote !== 'Not provided' && (
          <textarea className='PSCR-ContentTextArea'>
            {medicalNote}
          </textarea>
        )}
        <div className='PSCR-ContentsText'>Follow Up</div>
        {followUp && followUp !== 'None' && followUp !== 'Not provided' && (
          <textarea className='PSCR-ContentTextArea'>
            {followUp}
          </textarea>
        )}
        <div className='PSCR-ContentsText'>Double-Check Before Treatment</div>
        {doubleCheck && doubleCheck !== 'None' && doubleCheck !== 'Not provided' && (
          <textarea className='PSCR-ContentTextArea'>
            {doubleCheck}
          </textarea>
        )}
        <div className='PSCR-SubTitle Util-TopPadding-20px'>Authorized Signature (Pre-Counselor)
          <div className='PSCR-SignatureContainer'>
            <div className='PSCR-SignatureLeft'>
              <div className='PSCR-ContentsText'><span className='PSCR-ContentsText Gray'>Name:</span> {drName}</div>
              <div className='PSCR-ContentsText'><span className='PSCR-ContentsText Gray'>Date / Time:</span> {formatDate}</div>
              <div className='PSCR-ContentsText'><span className='PSCR-ContentsText White'>Date / Time:</span> {formatClock}</div>
            </div>
            <div className='PSCR-SignatureRight'>
              {drName}
            </div>
          </div>
        </div>
      </div>
      <div className='PSCR-BtnContainer'>
        <div className='PSCR-Btn cancle' onClick={btnCancle}>Cancle</div>
        <div className='PSCR-Btn Next' onClick={btnNext}>Next</div>
      </div>
    </div>
  );
}

export default PreConsultation;