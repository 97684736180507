import React, { useEffect, useState } from 'react';
import '../css/PreChannelListB.css';
import SendBird from 'sendbird';
import axios from 'axios';

const sb = new SendBird({ appId: process.env.REACT_APP_APP_ID });
const IP = process.env.REACT_APP_HOST;

const PreChannelListComponent = ({
  channels,
  onSelectChannel,
  activeTab,
  inactiveDiagnoses,
  unreadCounts,
  currentUser,
  getChannelName,
  selectedChannelUrl
}) => {
  const [channelDetails, setChannelDetails] = useState({});

  const formatMatchingTime = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${month}/${day} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };

  // 포맷 함수를 개선하여 timestamp를 "M/D (HH:MM)" 형식으로 반환
  const formatTimeWithParentheses = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${month}/${day} (${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')})`;
  };

  useEffect(() => {
    const fetchChannelDetails = async () => {
      const details = {};

      for (const channel of channels) {
        try {
          const fetchedChannel = await sb.GroupChannel.getChannel(channel.url);
          const response = await axios.post(`/PreChatList/PrgrsUserList`, {
            channelUrl: channel.url,
          });

          console.log("Channel details for", channel.url, ":", response.data);

          const res_chatPrgrs = response.data.chatPrgrs[0];

          const userData = await axios.post(`/UserProfile/getUserInfo_id`, {
            user_id: res_chatPrgrs.pre_user_id,
          });

          const response_user = userData.data;
          console.log("res chat prgrs2: ", response_user);

          if (response_user) {
            details[channel.url] = {
              userName: response_user.userInfo.user_firstname + " " + response_user.userInfo.user_lastname,
              age: response_user.userInfo.user_age,
              gender: response_user.userInfo.user_gender,
              isFrozen: fetchedChannel.isFrozen,
              matchingStartTime: res_chatPrgrs.pre_start_date,
            };
          } else {
            const chatEndResponse = await axios.post(`/PreChatList/EndUserList`, {
              channelUrl: channel.url,
            });

            const res_chatEnd = response.data.chatEndResponse[0];

            const userData = await axios.post(`/UserProfile/getUserInfo_id`, {
              user_id: res_chatEnd.pre_user_id,
            });

            const response_user = userData.data;

            if (response_user) {
              details[channel.url] = {
                endType: "pre",
                userName: response_user.userInfo.user_firstname + " " + response_user.userInfo.user_lastname,
                age: response_user.userInfo.user_age,
                gender: response_user.userInfo.user_gender,
                icon: chatEndResponse.data.chatEnd.iconImage,
                isFrozen: true,
                selectMedicalDep: chatEndResponse.data.chatEnd.selectMedicalDep,
                userDisease: chatEndResponse.data.chatEnd.userDisease,
                matchingStartTime: chatEndResponse.data.chatEnd.matchingStartTime,
                matchingEndTime: chatEndResponse.data.chatEnd.matchingEndTime,
                // 실제 진료 진행 여부 (예시)
                proceededToActual: chatEndResponse.data.chatEnd.proceededToActual || false,
              };
            }
          }
        } catch (error) {
          console.error('Error fetching channel details for', channel.url, ':', error);
        }
      }

      for (const channel of channels) {
        try {
          const fetchedChannel = await sb.GroupChannel.getChannel(channel.url);
          if (details[channel.url]) {
            details[channel.url].isFrozen = fetchedChannel.isFrozen;
          }
        } catch (error) {
          console.error('Error fetching channel frozen status:', error);
        }
      }

      setChannelDetails(details);
    };

    fetchChannelDetails();
  }, [channels, currentUser, inactiveDiagnoses]);

  const getChannelClassName = (channelUrl) => {
    const baseClass = 'PrechannelB-list-item';
    const isSelected = channelUrl === selectedChannelUrl;
    return `${baseClass}${isSelected ? ' selected' : ''}`;
  };

  // Active Tab UI
  const renderActiveChannelItem = (channel, details) => {
    const isSelected = channel.url === selectedChannelUrl;

    return (
      <div
        key={channel.url}
        className={`PrechannelB-list-item ${isSelected ? 'selected' : ''}`}
        onClick={() => onSelectChannel(channel.url)}
      >
        <div className="PrechannelB-list-content">
          <div className="PCB-containerA">{details.userName || 'Unknown'} <span className="PCB-labelA">({details.age || '0'}, {details.gender || 'N/A'})</span></div>
          <div className="PCB-containerB">Consultation Start Time: <span className="PCB-labelB">{formatMatchingTime(details.matchingStartTime)}</span>
            {unreadCounts[channel.url] > 0 && (
              <span className="PrechannelB-list-unread-badge">
                {unreadCounts[channel.url]}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  // Inactive Tab UI
  const renderInactiveChannelItem = (channel, details) => {
    const isSelected = channel.url === selectedChannelUrl;
    const proceededToActual = details.proceededToActual;

    return (
      <div
        key={channel.url}
        className={`PrechannelB-list-item ${isSelected ? 'selected' : ''}`}
        onClick={() => onSelectChannel(channel.url)}
      >
        <div className="PrechannelB-list-content">
          {/* 상태 표시 아이콘 (실제 진료 진행 여부에 따라 색상 다르게) */}
          <div className='PCL-stateNameBox'>
            <div className={`status-indicator ${proceededToActual ? 'green' : 'red'}`}></div>
            <div className="PrechannelB-list-details">
              <div className="PrechannelB-list-name">
                {details.userName || 'Unknown'}
              </div>
              <div className="PrechannelB-list-user-info">
                ({details.age || '0'}, {details.gender || 'N/A'})
              </div>
            </div>
          </div>
          {/* 진료과 정보 추가 */}
          {details.selectMedicalDep && (
            <div className="PrechannelB-list-department">
              {details.selectMedicalDep}
            </div>
          )}

          {/* 시작 시간 및 종료 시간 표시 - 상담 시간 형식으로 변경 */}
          <div className="PrechannelB-list-time-container">
            <div className="PrechannelB-list-time-header">
              Consultation Time
            </div>
            <div className="PrechannelB-list-time-range">
              {formatTimeWithParentheses(details.matchingStartTime)} - {formatTimeWithParentheses(details.matchingEndTime)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="PrechannelB-list">
      {channels.map(channel => {
        const details = channelDetails[channel.url] || {};

        // activeTab에 따라 다른 UI 렌더링
        return activeTab === 'active'
          ? renderActiveChannelItem(channel, details)
          : renderInactiveChannelItem(channel, details);
      })}
    </div>
  );
};

export default PreChannelListComponent;