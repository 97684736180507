import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import SendBird from 'sendbird';
import axios from 'axios';
import '../css/PreWebChatPage.css';

import PreConsultation from './PreConsultation'
import Pagination from './Pagination';
import NotificationComponent from './NotificationComponent';
import PatientHistory from './PatientHistory';
import ChatComponent from './ChatComponent';
import UserCallComponent from './UserCallComponent';
import PreChannelListA from './PreChannelListA';
import PreChannelListB from './PreChannelListB';
import ChannelListComponent from './ChannelList';
import PreExamination from './PreExamination';
import SidePreDrProfile from './SidePreDrProfile'
import FilteringComponent from './FilteringComponent'; // Import the Filtering Component
import PreCon_SelectDepartment from './PreCon_SelectDepartment'

import logoImage from './icons/Logo.png';
import searchIcon from './icons/search-icon.png';

const changeEmail = (email) => email.replace('@', '^');
const IP = process.env.REACT_APP_HOST;
const sb = new SendBird({ appId: process.env.REACT_APP_APP_ID });

function PreWebChatPage({ selectedChannelUrl, onSelectChannel }) {
  const [viewHistory, setViewHistory] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [completeData, setCompleteData] = useState([]);
  const navigate = useNavigate();
  const [sound, setSound] = useState();
  const [user, setUser] = useState(null);
  const [userCalls, setUserCalls] = useState([]);
  const [unreadCounts, setUnreadCounts] = useState({});
  const [channels, setChannels] = useState([]);
  const callSound = new Audio('/OccureCall.wav');
  const [isChatActive, setIsChatActive] = useState(false);
  const [hasPlayedSound, setHasPlayedSound] = useState(false);
  const [eventSource, setEventSource] = useState(null);
  const [sortPopup, setSortPopup] = useState(false);
  const [terminatedChannels, setTerminatedChannels] = useState([]);
  const [fetchIntervalActive, setFetchIntervalActive] = useState(true);
  const [data, setDrData] = useState({}); //의사 데이터
  const [infoData, setInfoData] = useState({}); //의사 개인정보 데이터
  const [ActData, setActData] = useState({}); // 의사 계정 설정 데이터
  const [userData, setUserData] = useState({}); //환자 데이터
  const [prgrsData, setPrgrsData] = useState({}); // 진행중 데이터
  const [selectedTab, setSelectedTab] = useState('active');
  const [isSearching, setIsSearching] = useState(false);
  const [filteredActiveChannels, setFilteredActiveChannels] = useState([]);
  const [tabIndicatorStyle, setTabIndicatorStyle] = useState({});
  const [filteredInactiveChannels, setFilteredInactiveChannels] = useState([]);
  const [isUserCallAccepted, setIsUserCallAccepted] = useState(false);
  const [inactiveDiagnoses, setInactiveDiagnoses] = useState({});
  const [activeChannelUrl, setActiveChannelUrl] = useState(null);
  const [selectedUserCall, setSelectedUserCall] = useState(null);
  const [selectedChatPrgrs, setSelectedChatPrgrs] = useState(null);
  const [isSelectedChannel, setIsSelectedChannel] = useState(false); // Changed variable name for clarity
  const [showExpiredCallPopup, setShowExpiredCallPopup] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showMedicalDepartment, setShowMedicalDepartment] = useState(false);
  const [showDrProfile, setShowDrProfile] = useState(false);
  const [isFrozen, setIsFrozen] = useState(false);
  const [showPatientHistoryPDF, setShowPatientHistoryPDF] = useState(false);
  const [patientNickname, setPatientNickname] = useState('');
  const [patientProfileUrl, setPatientProfileUrl] = useState('');
  const [isImpossibleModalOpen, setImpossibleModalOpen] = useState(false);
  const [isDiagnosisAlertOpen, setIsDiagnosisAlertOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedPrescriptionUrl, setSelectedPrescriptionUrl] = useState('');
  const [isPrescriptionModalOpen, setPrescriptionModalOpen] = useState(false);
  const [isSettingPopup, setSettingPopup] = useState(false);
  const email = localStorage.getItem('email');
  const [setMacroText, setMacroTextSetter] = useState(null);
  const [chatInputMessage, setChatInputMessage] = useState('');
  const [drName, setdrName] = useState([]);
  const [isPSCR_NextModalOpen, setPSCR_NextModalOpen] = useState(false);
  const [currentChatContent, setCurrentChatContent] = useState({
    messages: [],
    patientInfo: null,
    channelUrl: null
  });

  const setMacroPQ_1 = useCallback(() => {
    console.log("매크로 PQ_1 호출됨");
    setChatInputMessage("Please let us know your current symptoms or the reason you'd like to see a doctor.");
  }, []);
  const setMacroPQ_2 = useCallback(() => {
    console.log("매크로 PQ_2 호출됨");
    setChatInputMessage("What symptoms are you experiencing, and which areas of your body feel uncomfortable?");
  }, []);
  const setMacroPQ_3 = useCallback(() => {
    console.log("매크로 PQ_3 호출됨");
    setChatInputMessage("Do you have any specific concerns or suspected medical conditions?");
  }, []);
  const setMacroPQ_4 = useCallback(() => {
    console.log("매크로 PQ_4 호출됨");
    setChatInputMessage("How long have you been experiencing these symptoms?");
  }, []);
  const setMacroPQ_5 = useCallback(() => {
    console.log("매크로 PQ_5 호출됨");
    setChatInputMessage("1. Do you smoke? If so, how many times per week? \n2. Do you drink alcohol? If so, how many times per week?\n3. How many times per week do you engage in exercise that makes you short of breath?\n4. What is your occupation?");
  }, []);
  const setMacroPQ_6 = useCallback(() => {
    console.log("매크로 PQ_6 호출됨");
    setChatInputMessage("Do you have any past or current medical conditions? Please select all that apply.");
  }, []);
  const setMacroPQ_7 = useCallback(() => {
    console.log("매크로 PQ_7 호출됨");
    setChatInputMessage("Has anyone in your immediate family (parents, siblings) been diagnosed with any of the following conditions?");
  }, []);
  const setMacroPQ_8 = useCallback(() => {
    console.log("매크로 PQ_8 호출됨");
    setChatInputMessage("Macro8");
  }, []);
  const setMacroPQ_9 = useCallback(() => {
    console.log("매크로 PQ_9 호출됨");
    setChatInputMessage("Are you currently taking any prescription medications, over-the-counter drugs, or supplements? If so, please list them.");
  }, []);
  const setMacroPQ_10 = useCallback(() => {
    console.log("매크로 PQ_10 호출됨");
    setChatInputMessage("Do you have any allergies? If yes, please specify.");
  }, []);
  const setMacroPQ_11 = useCallback(() => {
    console.log("매크로 PQ_11 호출됨");
    setChatInputMessage("Macro11");
  }, []);
  const setMacroPQ_12 = useCallback(() => {
    console.log("매크로 PQ_12 호출됨");
    setChatInputMessage("Macro12");
  }, []);
  const setMacroPQ_13 = useCallback(() => {
    console.log("매크로 PQ_13 호출됨");
    setChatInputMessage("Macro13");
  }, []);

  // 필터링 관련 상태 추가
  const [activeFilters, setActiveFilters] = useState({
    gender: 'all',
    preExamination: 'all',
    department: []
  });

  const getChannelName = useCallback((channel, currentUserId) => {
    if (channel.name && channel.name !== 'Group Channel') {
      return channel.name;
    }
    const otherMembers = channel.members.filter(member => member.userId !== currentUserId);
    if (otherMembers.length > 0) {
      return otherMembers[0].nickname || otherMembers[0].userId;
    }
    return 'No name';
  }, []);

  const handleSearchChange = useCallback((e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setIsSearching(query.length > 0);

    const filterChannels = (channelList) => {
      return channelList.filter(channel => {
        const channelName = getChannelName(channel, user?.userId).split('(')[0].trim().toLowerCase();
        return channelName.includes(query);
      });
    };

    setFilteredActiveChannels(filterChannels(channels));
    setFilteredInactiveChannels(filterChannels(terminatedChannels));
  }, [channels, terminatedChannels, user, getChannelName]);

  const fetchChannels = useCallback(async () => {
    if (!user) return;

    try {
      const channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
      channelListQuery.includeEmpty = true;
      channelListQuery.limit = 20;

      const groupChannels = await new Promise((resolve, reject) => {
        channelListQuery.next((channels, error) => {
          if (error) reject(error);
          else resolve(channels);
        });
      });

      // 채널 상태를 확인하고 필터링
      const activeChannels = groupChannels.filter(channel => !channel.isFrozen);
      const inactiveChannels = groupChannels.filter(channel => channel.isFrozen);

      setChannels(activeChannels);
      setTerminatedChannels(inactiveChannels);

      handleSearchChange({ target: { value: searchQuery } });

      const newUnreadCounts = {};
      groupChannels.forEach(channel => {
        if (channel.unreadMessageCount > 0) {
          newUnreadCounts[channel.url] = channel.unreadMessageCount;
        }
      });
      setUnreadCounts(prev => ({ ...prev, ...newUnreadCounts }));
    } catch (error) {
      console.error('Error fetching channels:', error);
    }
  }, [user, searchQuery, handleSearchChange]);

  const tabsRef = useRef(null);

  const toggleHistoryView = () => {
    if (selectedUserCall) {
      setViewHistory(!viewHistory);
    }
  };

  const toggleCompleteView = (userCall, formData) => {
    setPrescriptionModalOpen(true);
    setSelectedUserCall(userCall);
    setCompleteData(formData);
  }

  const handlePatientSearch = (prescriptionUrl) => {
    setSelectedPrescriptionUrl(prescriptionUrl);
    setShowPatientHistoryPDF(true);
  };

  const handleOpenImpossibleModal = useCallback(async () => {
    try {
      const response = await axios.post('/ChatEnd/chatState', {
        channelUrl: selectedChannelUrl
      });

      if (response.status === 200) {
        fetchChannels();
        setImpossibleModalOpen(true);
      } else if (response.status === 201) {
        setIsDiagnosisAlertOpen(true);
      }
    } catch (error) {
      console.error('Error checking chat state:', error);
      // 에러 발생 시 진단을 하지 않은 것으로 간주
      setIsDiagnosisAlertOpen(true);
    }
  }, [selectedChannelUrl, fetchChannels]);



  function changeID(email) {
    return email.replace('^', '@');
  }

  const updateChatContent = async (channelUrl) => {
    try {
      const channel = await sb.GroupChannel.getChannel(channelUrl);
      const messageList = await channel.getMessagesByTimestamp(Date.now(), {
        prevResultSize: 100,
        nextResultSize: 0
      });

      const translatedMessages = await Promise.all(messageList.map(async (message) => {
        try {
          const translatedMsg = await message.translations['en'];
          return { ...message, translatedMessage: translatedMsg };
        } catch (error) {
          console.error('Translation error:', error);
          return message;
        }
      }));

      // 환자 정보 가져오기
      const response = await axios.post('/PreChatList/PrgrsUserList', {
        channelUrl: channelUrl
      });

      const res_chatPrgrs = response.data.chatPrgrs[0];
      
      const resUserData = await axios.post(`/UserProfile/getUserInfo_id`, {
        user_id: res_chatPrgrs.pre_user_id,
      });

      const response_user = resUserData.data;

      if (response_user) {
        setPrgrsData(res_chatPrgrs);
        setUserData(response_user);
        // // 환자 프로필 이미지 업데이트
        // if (chatPrgrs.iconImage) {
        //   const fullProfileUrl = `/icons/${chatPrgrs.iconImage}_Active.png`;
        //   setPatientProfileUrl(fullProfileUrl);
        // }

        // 채팅 컨텐츠 업데이트
        setCurrentChatContent({
          messages: translatedMessages,
          patientInfo: response_user,
          channelUrl: channelUrl
        });

        // // 환자 닉네임 업데이트 (필요한 경우)
        // if (chatPrgrs.userCall?.[0]?.userName) {
        //   setPatientNickname(chatPrgrs.userCall[0].userName);
        // }
      }

      setIsFrozen(channel.isFrozen);
    } catch (error) {
      console.error('Error updating chat content:', error);
      // 에러 발생 시 기본값 설정
      setPatientProfileUrl('');
      setPatientNickname('Unknown User');
      setCurrentChatContent({
        messages: [],
        patientInfo: null,
        channelUrl: channelUrl
      });
      setIsFrozen(false);
    }
  };



  const updateTabIndicator = (tab) => {
    if (tabsRef.current) {
      const activeTab = tabsRef.current.querySelector(`[data-tab="${tab}"]`);
      if (activeTab) {
        const { offsetLeft, offsetWidth } = activeTab;
        setTabIndicatorStyle({
          left: `${offsetLeft}px`,
          width: `${offsetWidth}px`,
        });
      }
    }
  };

  const goMain = () => {
    //navigate('/WebChat');
    window.location.reload();
  }
  const GoToIncome = () =>{
    navigate('/pay');
  }

  const toggleSound = async () => {
    try {
      // DB 업데이트
      await axios.post('/Save/updateSound', {
        email: email,
        soundActive: !sound
      }, {
        withCredentials: true
      });
  
      // 상태 업데이트
      setSound(!sound);
      setHasPlayedSound(false);
      
      // 음소거 상태 업데이트
      if (callSound) {
        callSound.muted = sound;
      }
    } catch (error) {
      console.error('Error updating sound settings:', error);
    }
  };

   useEffect(() => {
      setHasPlayedSound(false); // 페이지 새로고침시 재생 상태 초기화
      
      return () => {
        callSound.pause();
        callSound.currentTime = 0;
      };
    }, []);

  // 최신 상태값 확인
  useEffect(() => {
    console.log("업데이트된 userData:", userData);
  }, [userData]);

  useEffect(() => {
    console.log("업데이트된 prgrsData:", prgrsData);
  }, [prgrsData]);


  const handleSelectChannel = async (url) => {
    setFetchIntervalActive(false);
    onSelectChannel(url);
    setActiveChannelUrl(url);
    setIsChatActive(true);  // 채팅이 시작될 때 상태를 true로 설정
    setIsSelectedChannel(true); // Set channel as selected to trigger UI changes

    try {
      const channel = await sb.GroupChannel.getChannel(url);
      setIsUserCallAccepted(true);

      // 채널을 읽음으로 표시
      await channel.markAsRead();
      setUnreadCounts(prev => ({ ...prev, [url]: 0 }));

      // getUserCallInfo API 호출
      const response = await axios.post(`/PreChatList/PrgrsUserList`, {
        channelUrl: url,
      });

      const res_chatPrgrs = response.data.chatPrgrs[0];
      
      const resUserData = await axios.post(`/UserProfile/getUserInfo_id`, {
        user_id: res_chatPrgrs.pre_user_id,
      });

      const response_user = resUserData.data;

      if (response_user) {

        // 채팅 내용 업데이트
        await updateChatContent(url);

        // 환자 정보 설정
        if (response_user) {
          setSelectedChatPrgrs(res_chatPrgrs);
          setSelectedUserCall(response_user);
          setUserData(response_user);
          setPrgrsData(res_chatPrgrs);
          console.log("하하하: ", userData);
          console.log("하하하2: ", prgrsData);
        }
      }

      setIsFrozen(channel.isFrozen);
    } catch (error) {
      console.error('Error in handleSelectChannel:', error);
      setSelectedUserCall(null);
      setIsFrozen(false);
      setPatientNickname('Unknown User');
    }
  };

  const handleBackToChannelList = () => {
    setIsSelectedChannel(false);
    setActiveChannelUrl(null);
    setIsChatActive(false);
    setFetchIntervalActive(true);
  };

  const handleAcceptCall = async (userCall) => {
    setFetchIntervalActive(true);

    try {
      const response = await axios.post('/DrChatMain/callAccept', {
        userEmail: userCall.userId,
        drEmail: email,
      });

      if (response.status === 200) {
        const userIdSB = changeEmail(userCall.userId); // SendBird 사용자 ID 변경
        const drIdSB = data.dr_sb_uuid; // SendBird 의사 ID 변경

        const channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
        channelListQuery.userIdsExactFilter = [userIdSB, drIdSB]; // 채널 목록 쿼리 설정

        try {
          const [groupChannels] = await new Promise((resolve, reject) => {
            channelListQuery.next((channels, error) => {
              if (error) reject(error);
              else resolve([channels]);
            });
          });

          let channel;
          if (groupChannels.length > 0) {
            channel = groupChannels[0];
          } else {
            channel = await new Promise((resolve, reject) => {
              sb.GroupChannel.createChannel([userIdSB, drIdSB], (createdChannel, error) => {
                if (error) reject(error);
                else resolve(createdChannel);
              });
            });
            setChannels(prevChannels => [...prevChannels, channel]);
          }

          // 채널 선택 및 채팅 내용 업데이트
          await handleSelectChannel(channel.url);
          setSelectedUserCall(userCall);
          setFetchIntervalActive(false);
        } catch (error) {
          console.error('Error in channel operations:', error);
        }
      }
    } catch (error) {
      console.error('Error accepting user call:', error);
      if (error.response && error.response.status === 400) {
        setShowExpiredCallPopup(true);
      }
    }
  };

  const handleCloseDrProfile = () => {
    setShowDrProfile(false);
  };

  const handleOpenMedicalDepartment = () => {
    setShowMedicalDepartment(true);
  };

  const handleCancelCall = (userCall) => {
    setIsUserCallAccepted(false);
    setSelectedUserCall(null);
  };
  const handleProfileClick = () => {
    setShowDrProfile(true);
  };


  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    updateTabIndicator(tab);
  };

  // 필터 적용 함수 추가
  const applyFilters = (newFilters) => {
    setActiveFilters(newFilters);

    // 활성 채널과 비활성 채널에 필터 적용
    const applyChannelFilter = (channelList) => {
      return channelList.filter(channel => {
        // 실제 채널 데이터에 맞게 필터 로직 구현 필요
        // 예시: 성별 필터링
        if (newFilters.gender !== 'all') {
          const metadata = channel.metaData || {};
          if (metadata.gender !== newFilters.gender) {
            return false;
          }
        }

        // 예시: Pre-examination 필터링
        if (newFilters.preExamination !== 'all') {
          const metadata = channel.metaData || {};
          if (metadata.preExamination !== newFilters.preExamination) {
            return false;
          }
        }

        // 예시: 부서 필터링
        if (newFilters.department.length > 0 && !newFilters.department.includes('allMedical')) {
          const metadata = channel.metaData || {};
          if (!newFilters.department.includes(metadata.department)) {
            return false;
          }
        }

        return true;
      });
    };

    // 필터 적용된 채널 목록 업데이트
    const filteredActive = applyChannelFilter(channels);
    const filteredInactive = applyChannelFilter(terminatedChannels);

    setFilteredActiveChannels(filteredActive);
    setFilteredInactiveChannels(filteredInactive);

    // 설정 팝업 닫기
    setSettingPopup(false);
  };

  useEffect(() => {
      const eventSource = new EventSource(`/api/sse?email=${email}`);
      console.log(`/api/sse?email=${email}`);
    
      eventSource.onmessage = (event) => {
        try {
          const newEvent = JSON.parse(event.data);
          // 새로운 콜이 있고, 소리가 켜져있고, 아직 재생되지 않았을 때만 소리 재생
          if (newEvent.length > userCalls.length && sound && !hasPlayedSound) {
            callSound.play()
              .then(() => {
                setHasPlayedSound(true);
              })
              .catch(error => {
                console.error('Error playing sound:', error);
              });
          }
          setUserCalls(newEvent);
        } catch (e) {
          console.error('Failed to parse event data:', e);
        }
      };
    
      eventSource.onerror = (error) => {
        console.error('EventSource failed: ', error);
        eventSource.close();
      };
      setEventSource(eventSource);
      return () => {
        eventSource.close();
      };
    }, [email, sound, userCalls.length, hasPlayedSound]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (fetchIntervalActive) {
        fetchChannels();
      }
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, [searchQuery, selectedTab, user, fetchIntervalActive, fetchChannels]);

  useEffect(() => {
    const fetchDoctorInfo = async () => {
      try {
        const response = await axios.get('/api/session-email', { withCredentials: true });
        if (response.status === 401) {
          navigate('/Login');
          return;
        }

        const Dremail = response.data.email;

        const DrInfoResponse = await axios.post('/PreSave/getDrInfo', { drEmail: Dremail }, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // Doctor's information
        const doctorInfo = DrInfoResponse.data.preDr;
        setDrData(doctorInfo);

        const doctorInfo2 = DrInfoResponse.data.preDrInfo;
        setInfoData(doctorInfo2);

        const doctorActInfo = DrInfoResponse.data.preDrActInfo;
        setActData(doctorActInfo);

        // sound 상태 초기화 추가
        setSound(doctorInfo.dr_soundActive || false);

        // dr_sb_email 필드 값 가져오기
        const drSbEmail = doctorInfo.pre_dr_sb_uuid;
        const drName = `${doctorInfo2.pre_dr_firstname} ${doctorInfo2.pre_dr_lastname}`
        //const drSbEmail = doctorInfo.userId;

        setdrName(drName);

        const connectedUser = await sb.connect(drSbEmail, 'email');
        sb.updateCurrentUserInfo(drName, null, (response, error) => {
          if (error) {
            console.error('Error updating user info:', error);
            return;
          }
          setUser(connectedUser);
        });
      } catch (error) {
        console.error('Error fetching doctor info:', error);
      }
    };

    fetchDoctorInfo();
  }, [navigate]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const sortingPopUp = () => {
    setSortPopup(!sortPopup);
  };

  const settingPopup = () => {
    setSettingPopup(!isSettingPopup);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    console.log("Toggling expand state:", !isExpanded);
    
    if (!isExpanded) {
      // When expanding, show all channels by setting a high itemsPerPage
      setItemsPerPage(1000); // A large number to show all channels
    } else {
      // When collapsing, reset to normal pagination
      setItemsPerPage(5);
      // Reset to page 1 when collapsing to ensure we're at the beginning
      setCurrentPage(1);
    }
  };
  

  const getChannelsForCurrentPage = () => {
    const currentChannels = selectedTab === 'active' ? filteredActiveChannels : filteredInactiveChannels;
    
    if (isExpanded) {
      // When expanded, return all channels without pagination
      return currentChannels;
    } else {
      // When not expanded, return paginated channels
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      return currentChannels.slice(indexOfFirstItem, indexOfLastItem);
    }
  };
  const totalPages = Math.ceil(
    (selectedTab === 'active' ? filteredActiveChannels.length : filteredInactiveChannels.length) / itemsPerPage
  );

  const PCSR_btnCancle = () =>{
    setPrescriptionModalOpen(false);
  }

  const PCSR_btnNext = () =>{
    setPrescriptionModalOpen(false);
    setPSCR_NextModalOpen(true);
  }

  const PCSR_SD_btnCancle = () =>{
    setPrescriptionModalOpen(true)
    setPSCR_NextModalOpen(false);
  }
  const PCSR_SD_btnNext = () =>{}

  return (
    <div className="PWC-app-container">
      <div className="PWC-header">
        <div className="PWC-header-left">
          <button onClick={goMain} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
            <img src={logoImage} alt="MEDINOX Logo" className="PWC-logo-image" />
          </button>
        </div>

        <div className="PWC-header-right">
          {/* <button
            className='PWC'
            onClick={toggleSound}
            style={{ display: "contents" }}>
            <img
              src={sound ? "./icons/web_icon/sound_on.png" : "./icons/web_icon/sound_mute.png"}
              alt={sound ? "Sound On" : "Sound Off"}
              style={{ width: "32px", height: "auto" }}
            />
          </button>
          <NotificationComponent
            userCalls={userCalls}
            handleAcceptCall={handleAcceptCall}
            handleCancelCall={handleCancelCall}
            isChatActive={isChatActive}
          /> */}
          <div className="PWC-rate-container">
            <img src="./icons/web_icon/img_rate.png" alt="TTABONG" className="PWC-rate-img" />
            <div className="PWC-rate-text"><span style={{ color: "#01B6CD" }}>{infoData.pre_dr_rating}</span> / 5</div>
          </div>
          <button className="PWC-drprofile-button" onClick={handleProfileClick}>
            <img
              loading="lazy"
              src={`${infoData.pre_dr_profile_photo}`}
              alt="Doctor Profile"
              className="PWC-drprofile-img"
            />
          </button>
        </div>
        {showDrProfile && (
          <>
            {/* <SideDrProfile
              drInfo={{
                name: `${infoData.pre_dr_firstname} ${infoData.pre_dr_lastname} `,
                profileImage: `${infoData.pre_dr_profile_photo}`,
                dr_year: `${infoData.pre_dr_birth[0].pre_dr_year}`,
                dr_month: `${infoData.pre_dr_birth[0].pre_dr_month}`,
                dr_day: `${infoData.pre_dr_birth[0].pre_dr_day}`,
                gender: `${infoData.pre_dr_gender}`,
                hospitalAddress: `${infoData.pre_dr_live[0].pre_dr_address}, ${infoData.pre_dr_live[0].pre_dr_state}, ${infoData.pre_dr_live[0].pre_dr_city}, ${infoData.pre_dr_live[0].pre_dr_zipcode}`,
                hospitalAddress2: `${infoData.pre_dr_live[0].pre_dr_address2}`,
                greeting: `d`,
                closingRemarks: `d`,
                PhoneNumber: `${infoData.pre_dr_phonenumber}`,
              }}
              onClose={handleCloseDrProfile}
              isOpen={showDrProfile}
              onOpenMedicalDepartment={handleOpenMedicalDepartment}
            /> */}
            <SidePreDrProfile
              drInfo={{
                name: `${infoData.pre_dr_firstname} ${infoData.pre_dr_lastname} `,
                profileImage: `${infoData.pre_dr_profile_photo}`,
                dr_year: `${infoData.pre_dr_birth[0].pre_dr_year}`,
                dr_month: `${infoData.pre_dr_birth[0].pre_dr_month}`,
                dr_day: `${infoData.pre_dr_birth[0].pre_dr_day}`,
                gender: `${infoData.pre_dr_gender}`,
                hospitalAddress: `${infoData.pre_dr_live[0].pre_dr_address}, ${infoData.pre_dr_live[0].pre_dr_state}, ${infoData.pre_dr_live[0].pre_dr_city}, ${infoData.pre_dr_live[0].pre_dr_zipcode}`,
                hospitalAddress2: `${infoData.pre_dr_live[0].pre_dr_address2}`,
                greeting: `d`,
                closingRemarks: `d`,
                PhoneNumber: `${infoData.pre_dr_phonenumber}`,
              }}
              onClose={handleCloseDrProfile}
              isOpen={showDrProfile}
              onOpenMedicalDepartment={handleOpenMedicalDepartment}
            />
          </>
        )}
      </div>
      <div className="PWC-content">
        {/* Sidebar - class changes based on channel selection */}
        <div className={isSelectedChannel ? "WWC-sidebar" : "PWC-sidebar"}>
          {!isSelectedChannel ? (
            // Original sidebar content when no channel is selected
            <>
              <div className="PWC-search">
                <div className="PWC-gene-div">
                  <div className="PWC-search-container">
                    <img src={searchIcon} alt="Search" className="PWC-search-icon" />
                    <input
                      type="text"
                      className="PWC-search-input"
                      placeholder="Search here"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              </div>
              <div className="PWC-tabs" ref={tabsRef}>
                <button className={`PWC-tab ${selectedTab === 'active' ? 'active' : ''}`}
                  onClick={() => handleTabChange('active')} data-tab="active">
                  Active
                  {isSearching && selectedTab === 'inactive' && filteredActiveChannels.length > 0 &&
                    <span className="other-tab-count">{filteredActiveChannels.length}</span>}
                </button>
                <button
                  className={`PWC-tab ${selectedTab === 'inactive' ? 'active' : ''}`}
                  onClick={() => handleTabChange('inactive')} data-tab="inactive" >
                  Inactive
                  {isSearching && selectedTab === 'active' && filteredInactiveChannels.length > 0 &&
                    <span className="other-tab-count">{filteredInactiveChannels.length}</span>}
                </button>
                <div className={`PWC-tab-indicator ${selectedTab}`} style={tabIndicatorStyle}></div>
                <div className="PWC-tab-indicator-background"></div>
              </div>
              <div className={`PWC-ListBox ${isExpanded ? 'expanded' : ''}`}>
                <div className="PWC-indexName">
                  <div className="PWC-IN-container">
                    <div className="PWC-publicIN IN1">Patient Information </div>
                    {selectedTab === 'inactive' ? (
                      <div className="PI-Script">
                        <img src="./icons/web_icon/WIHover.png" alt="" style={{ width: "14px", height: "14px" }} />
                        <div className="tooltip">
                          <div className="tooltip-header">List of Patients Who Completed Pre-Consultation</div>
                          <div className="tooltip-item">
                            <div className="tooltip-indicator tooltip-green"></div>
                            <div>- Patients who proceeded to actual consultation</div>
                          </div>
                          <div className="tooltip-item">
                            <div className="tooltip-indicator tooltip-red"></div>
                            <div>- Patients who ended at pre-consultation only</div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="PI-Script">&nbsp;&nbsp;&nbsp;</div>
                    )}
                  </div>
                  {selectedTab === 'inactive' ? 
                    <div className="PWC-publicIN IN2">Department Assignment</div>:
                    <div className="PWC-publicIN IN2">Consultation Start Time</div>
                  }
                  <div className="PWC-publicIN IN3">
                    <button className="PWC-publicIN_IN3_btn1" onClick={() => sortingPopUp()}>
                      <img src="./icons/web_icon/btn_clock.png" alt=""></img>
                    </button>
                    <button className="PWC-publicIN_IN3_btn1" onClick={() => settingPopup()}>
                      <img src="./icons/web_icon/btn_setting.png" alt=""></img>
                    </button>
                  </div>
                  {sortPopup === true ? <div className="sortingPopup">
                    <div className="sort_type">Oldest First</div>
                    <div className="sort_type">Newest First</div>
                    <div className="sort_type">A to Z</div>
                    <div className="sort_type">Z to A</div>
                  </div> : <></>}
                </div>
                <PreChannelListA
                  channels={getChannelsForCurrentPage()}
                  onSelectChannel={handleSelectChannel}
                  activeTab={selectedTab}
                  inactiveDiagnoses={inactiveDiagnoses}
                  unreadCounts={unreadCounts}
                  getChannelName={getChannelName}
                  currentUser={user}
                  selectedChannelUrl={activeChannelUrl}
                />
              </div>
              <button className="expandBtn" onClick={toggleExpand}>
                <img
                  src={isExpanded ? "./icons/web_icon/BtnCollapseView.png" : "./icons/web_icon/BtnExpandingView.png"}
                  alt={isExpanded ? "Collapse View" : "Expand View"}
                />
              </button>
              {!isExpanded && (
                <div className="PageIndex">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              )}
              {isSettingPopup && (
                <FilteringComponent
                  isOpen={isSettingPopup}
                  onClose={() => setSettingPopup(false)}
                  onApply={applyFilters}
                />
              )}
            </>
          ) : (
            // Collapsed sidebar when channel is selected
            <div className="wcc-sidebar">
              <div className="wcc-search">
                <div className="wcc-gene-div">
                  <div className="wcc-search-container">
                    <img src={searchIcon} alt="Search" className="wcc-search-icon" />
                    <input
                      type="text"
                      className="wcc-search-input"
                      placeholder="Search here"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              </div>
              <div className="wcc-tabs" ref={tabsRef}>
                <button className={`tab ${selectedTab === 'active' ? 'active' : ''}`} data-tab="active">
                  Patient List
                  {isSearching && selectedTab === 'inactive' && filteredActiveChannels.length > 0 &&
                    <span className="other-tab-count">{filteredActiveChannels.length}</span>}
                </button>
              </div>
              {/* When a channel is selected, use PreChannelListB */}
              <PreChannelListB
                channels={selectedTab === 'active' ? filteredActiveChannels : filteredInactiveChannels}
                onSelectChannel={handleSelectChannel}
                activeTab={selectedTab}
                inactiveDiagnoses={inactiveDiagnoses}
                unreadCounts={unreadCounts}
                getChannelName={getChannelName}
                currentUser={user}
                selectedChannelUrl={activeChannelUrl}
              />
            </div>
          )}
        </div>

        {/* Chat Content Area */}
        {isSelectedChannel && (
          <div className="wcc-main-content">
            {!isUserCallAccepted ? (
              <div>
                <div className="wcc-call-list">
                  <div className="wcc-call-list-header">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img src="./icons/web_icon/CallListBell.png" alt="Call" className="wcc-call-icon" />
                      <span className="wcc-call-list-title">Call list</span>
                    </div>
                    <span className="wcc-call-count">{userCalls.length} Calls</span>
                  </div>
                </div>
                <div className="wcc-user-calls-list">

                  {userCalls.map(userCall => (
                    <UserCallComponent
                      key={userCall.id}
                      userCall={userCall}
                      handleAcceptCall={() => handleAcceptCall(userCall)}
                      handleCancelCall={() => handleCancelCall(userCall)}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className="wcc-chat-container">
                {isUserCallAccepted && (
                  <ChatComponent
                    appId={process.env.REACT_APP_APP_ID}
                    userId={data.pre_dr_sb_uuid}
                    channelUrl={activeChannelUrl}
                    patientNickname={patientNickname}
                    isFrozen={isFrozen}
                    messages={currentChatContent.messages}
                    patientProfileUrl={patientProfileUrl}
                    inputMessage={chatInputMessage}
                    setInputMessage={setChatInputMessage}
                  />
                )}
                <div className="wcc-patient-info-container">
                  {viewHistory ? (
                    <PatientHistory
                      userCall={selectedUserCall}
                      onBack={toggleHistoryView}
                      onPatientSearch={handlePatientSearch}
                    />
                  ) : (
                    <PreExamination
                      userCall={selectedUserCall}
                      chatPrgrs = {selectedChatPrgrs}
                      drInfo={data}
                      openCompleteConsultation={toggleCompleteView}
                      onViewHistory={toggleHistoryView}
                      onImpossibleTreatment={handleOpenImpossibleModal}
                      isChannelFrozen={isFrozen}
                      patientInfo={currentChatContent.patientInfo}
                      patientProfileUrl={patientProfileUrl}
                      setMacroPQ_1={setMacroPQ_1}
                      setMacroPQ_2={setMacroPQ_2}
                      setMacroPQ_3={setMacroPQ_3}
                      setMacroPQ_4={setMacroPQ_4}
                      setMacroPQ_5={setMacroPQ_5}
                      setMacroPQ_6={setMacroPQ_6}
                      setMacroPQ_7={setMacroPQ_7}
                      setMacroPQ_8={setMacroPQ_8}
                      setMacroPQ_9={setMacroPQ_9}
                      setMacroPQ_10={setMacroPQ_10}
                      setMacroPQ_11={setMacroPQ_11}
                      setMacroPQ_12={setMacroPQ_12}
                      setMacroPQ_13={setMacroPQ_13}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {isPrescriptionModalOpen && (
          <PreConsultation 
            formData={completeData} 
            drName={drName}
            drInfo = {infoData}
            btnCancle={PCSR_btnCancle}
            btnNext={PCSR_btnNext}
          >
          </PreConsultation>
        )}
        {isPSCR_NextModalOpen && (
          <PreCon_SelectDepartment
            formData={completeData} 
            drName={drName}
            drInfo = {infoData}
            medical_id = {prgrsData.medical_history_id}
            userEmail = {userData.user.user_email}
            btnCancle={PCSR_SD_btnCancle}
            btnNext={PCSR_SD_btnNext}
          >
          </PreCon_SelectDepartment>
        )}
      </div>
    </div>
  );
}

export default PreWebChatPage;