import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './QuestionPage.css';

// 질문 목록과 점수 할당
const questions = {
  en: [
    { question: "Do you smoke?", yesScore: 3, noScore: 0 },
    { question: "Do you drink alcohol?", yesScore: 2, noScore: 0 },
    { question: "Do you engage in high-intensity physical activities at least once a week?", yesScore: 0, noScore: 1 },
    { question: "Are you 65 years or older?", yesScore: 3, noScore: 0 },
    { question: "Do you have a family history of serious health conditions?", yesScore: 3, noScore: 0 },
    { question: "Have you been diagnosed with serious health conditions?", yesScore: 3, noScore: 0 },
    { question: "Do you feel any discomfort in your body?", yesScore: 2, noScore: 0 },
    { question: "Have you lost more than 5 kg of weight rapidly?", yesScore: 3, noScore: 0 },
    { question: "Do you experience discomfort when urinating?", yesScore: 2, noScore: 0 },
    { question: "Do you experience discomfort during bowel movements?", yesScore: 2, noScore: 0 },
    { question: "Do you drink more than 1 liter of water per day?", yesScore: 0, noScore: 1 },
    { question: "Do you often feel down or depressed?", yesScore: 3, noScore: 0 }
  ],
  ko: [
    { question: "흡연을 하십니까?", yesScore: 3, noScore: 0 },
    { question: "음주를 하십니까?", yesScore: 2, noScore: 0 },
    { question: "평소 1주일간, 숨이 차게 만드는 고강도 신체활동을 1회 이상 하십니까?", yesScore: 0, noScore: 1 },
    { question: "65세 이상입니까?", yesScore: 3, noScore: 0 },
    { question: "부모, 형제, 자매 중에 뇌졸중, 심근경색 / 협심증, 고혈압, 당뇨병, 암 질환을 앓았거나 해당 질환으로 사망한 경우가 있으십니까?", yesScore: 3, noScore: 0 },
    { question: "뇌졸중, 심근경색 / 협심증, 고혈압, 당뇨병, 암 질환을 본인도 진단을 받았거나, 현재 약물 치료 중이십니까?", yesScore: 3, noScore: 0 },
    { question: "신체 어느 부위든 불편한 곳이 있으십니까?", yesScore: 2, noScore: 0 },
    { question: "지난 한 달 동안 급격히 5kg 이상 체중이 감소했습니까?", yesScore: 3, noScore: 0 },
    { question: "소변을 보는 데 불편함이 있으십니까?", yesScore: 2, noScore: 0 },
    { question: "대변을 보는 데 불편함이 있으십니까?", yesScore: 2, noScore: 0 },
    { question: "하루에 1리터 이상의 물을 마십니까?", yesScore: 0, noScore: 1 },
    { question: "종종 우울감이나 슬픔을 느끼십니까?", yesScore: 3, noScore: 0 }
  ],
  es: [
    { question: "¿Fumas?", yesScore: 3, noScore: 0 },
    { question: "¿Bebes alcohol?", yesScore: 2, noScore: 0 },
    { question: "¿Realizas actividades físicas de alta intensidad al menos una vez a la semana?", yesScore: 0, noScore: 1 },
    { question: "¿Tienes 65 años o más?", yesScore: 3, noScore: 0 },
    { question: "¿Tienes antecedentes familiares de condiciones de salud graves?", yesScore: 3, noScore: 0 },
    { question: "¿Te han diagnosticado condiciones de salud graves?", yesScore: 3, noScore: 0 },
    { question: "¿Sientes alguna molestia en tu cuerpo?", yesScore: 2, noScore: 0 },
    { question: "¿Has perdido más de 5 kg de peso rápidamente?", yesScore: 3, noScore: 0 },
    { question: "¿Sientes molestias al orinar?", yesScore: 2, noScore: 0 },
    { question: "¿Sientes molestias al evacuar?", yesScore: 2, noScore: 0 },
    { question: "¿Bebes más de 1 litro de agua al día?", yesScore: 0, noScore: 1 },
    { question: "¿A menudo te sientes triste o deprimido?", yesScore: 3, noScore: 0 }
  ]
};

function QuestionPage() {
  const location = useLocation();
  const { language, email  } = location.state || { language: 'en' }; // 언어 정보를 받아오고 기본값을 설정
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [totalScore, setTotalScore] = useState(0); // 총 점수를 저장
  const [responses, setResponses] = useState([]); // 응답 배열 생성
  const navigate = useNavigate();

  const handleAnswer = (answer) => {
    const current = questions[language][currentQuestion];
    const score = answer === 'yes' ? current.yesScore : current.noScore;

    setTotalScore(totalScore + score); // 총 점수 업데이트
    setResponses([...responses, answer]); // 응답 배열에 추가

    if (currentQuestion < questions[language].length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      // 최종 결과 페이지로 내비게이션할 때 총 점수 전달
      navigate('/Result', { state: {  totalScore: totalScore + score, responses: [...responses, answer], email, language } });
    }
  };

  return (
    <div className="QP-main-body">
      <div className="QP-main-container">
        <div className="OP-question-group">
          <div className="QP-prgrsBar">Question {currentQuestion + 1} of {questions[language].length}</div>
          <h2 className="QP-main-title">{questions[language][currentQuestion].question}</h2>
        </div>

        <div className="QP-bottom-items">
          <button onClick={() => handleAnswer('yes')} className="QP-btn-answer">Yes</button>
          <button onClick={() => handleAnswer('no')} className="QP-btn-answer-no">No</button>
        </div>
      </div>
    </div>
  );
}

export default QuestionPage;
