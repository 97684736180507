import axios from 'axios';
import { useEffect, useState } from 'react';
import SendBirdCall from 'sendbird-calls';

// SendBird 초기화 설정
const APP_ID = 'D3977B5D-9B96-4530-A606-88DA7E02906A';
const USER_ID = 'qmfpdls4908^naver.com';
const ACCESS_TOKEN = '3b37a9f0bc2d007643493c12409b1832d1feb780';

export const useCallManager = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentCall, setCurrentCall] = useState(null);
  const [incomingCall, setIncomingCall] = useState(null);
  const [callStatus, setCallStatus] = useState('대기 중');
  const [callId, setCallId] = useState(null);


  useEffect(() => {
    const initializeCall = async () => {
      try {
        SendBirdCall.init(APP_ID);
        console.log("SendBird 초기화 완료...");
        
        await new Promise((resolve, reject) => {
          SendBirdCall.authenticate({ userId: USER_ID, accessToken: ACCESS_TOKEN }, (result, error) => {
            if (error) {
              console.error("사용자 인증 에러:", error);
              reject(error);
            } else {
              setIsAuthenticated(true);
              resolve(result);
            }
          });
        });

        try {
          await SendBirdCall.connectWebSocket();
          console.log("웹 소켓 연결 성공...");
        } catch (error) {
          console.log("웹 소켓 연결 실패...");
          console.log("상세 로그: ", error);
        }

        SendBirdCall.addListener('UNIQUE_HANDLER_ID', {
          onRinging: handleIncomingCall
        });

      } catch (error) {
        console.error('SendBird 초기화 실패:', error);
        setCallStatus('초기화 실패');
        setIsAuthenticated(false);
      }
    };

    initializeCall();
    
    return () => {
      SendBirdCall.removeListener('UNIQUE_HANDLER_ID');
    };
  }, []);

  const handleIncomingCall = (call) => {
    console.log("수신 통화 감지:", call);
    setCallStatus('수신 전화 도착');
    setIncomingCall(call);

    call.onEstablished = () => {
      console.log('통화 설정됨');
      setCallStatus('연결 중...');
    };

    call.onConnected = () => {
      console.log('통화 연결됨');
      setCallStatus('통화 중');
      setCurrentCall(call);
      setIncomingCall(null);
    };

    call.onEnded = () => {
      console.log('통화 종료됨');
      setCallStatus('통화 종료');
      setCurrentCall(null);
      setIncomingCall(null);
    };

    call.onRemoteAudioSettingsChanged = () => {
      console.log('상대방 오디오 설정 변경');
    };

    call.onRemoteVideoSettingsChanged = () => {
      console.log('상대방 비디오 설정 변경');
    };
  };

  const acceptCall = async (localVideoRef, remoteVideoRef) => {
    if (incomingCall) {
      const acceptParams = {
        callOption: {
          localMediaView: localVideoRef.current,
          remoteMediaView: remoteVideoRef.current,
          audioEnabled: true,  // 오디오 기본값 활성화로 변경
          videoEnabled: false  // 비디오는 사용자 요청에 따라 변경 가능
        }
      };
      
      try {
        await incomingCall.accept(acceptParams);
      } catch (error) {
        console.error('통화 수락 실패:', error);
      }
    }
  };

  const rejectCall = () => {
    if (incomingCall) {
      incomingCall.end();
      setIncomingCall(null);
      setCallStatus('대기 중');
    }
  };

  // 수신자 ID를 매개변수로 받도록 수정
  const startCall = async (localVideoRef, remoteVideoRef, calleeId = 'lamer5130^gmail.com', channelUrl) => {
    try {
      // Debug logging to check refs
      console.log('Starting call with refs:', {
        localVideoRef: localVideoRef?.current ? 'exists' : 'missing',
        remoteVideoRef: remoteVideoRef?.current ? 'exists' : 'missing'
      });
      
      // Validate refs before proceeding
      if (!localVideoRef?.current || !remoteVideoRef?.current) {
        console.error('Video refs not properly initialized');
        
        // Add workaround to create DOM elements if refs don't exist
        if (!localVideoRef?.current) {
          console.warn('Creating fallback local video element');
          const videoElement = document.createElement('video');
          videoElement.autoplay = true;
          videoElement.playsInline = true;
          videoElement.muted = true;
          localVideoRef = { current: videoElement };
        }
        
        if (!remoteVideoRef?.current) {
          console.warn('Creating fallback remote video element');
          const videoElement = document.createElement('video');
          videoElement.autoplay = true;
          videoElement.playsInline = true;
          remoteVideoRef = { current: videoElement };
        }
      }
      
      // Request media permissions before starting call
      try {
        console.log('Requesting media permissions...');
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true
        });
        
        // Preview local video
        if (localVideoRef?.current) {
          console.log('Setting preview on local video element');
          localVideoRef.current.srcObject = stream;
          await localVideoRef.current.play().catch(e => 
            console.warn('Preview play warning (can be ignored):', e)
          );
        }
        
        // We don't stop tracks here because SendBird needs them
        // stream.getTracks().forEach(track => track.stop());
      } catch (mediaError) {
        console.error('Failed to get media permissions:', mediaError);
        setCallStatus('Media permission denied');
        return null;
      }
  
      // Configure call parameters with defensive coding
      const dialParams = {
        userId: calleeId,
        isVideoCall: true,
        callOption: {
          localMediaView: localVideoRef?.current,
          remoteMediaView: remoteVideoRef?.current,
          audioEnabled: true,
          videoEnabled: true
        }
      };
  
      console.log('Dialing with params:', JSON.stringify({
        userId: dialParams.userId,
        isVideoCall: dialParams.isVideoCall,
        hasLocalMedia: !!dialParams.callOption.localMediaView,
        hasRemoteMedia: !!dialParams.callOption.remoteMediaView
      }));
  
      // Initiate the call
      const call = SendBirdCall.dial(dialParams, (call, error) => {
        if (error) {
          console.error('Call dialing failed:', error);
          setCallStatus('Call failed');
          return;
        }
        console.log('Call dialing successful');
        console.log('aaaaa', call.callId);
        axios.post("/sb_call", {
          channelUrl: channelUrl,
          call_id: call.callId
        });
      });
      
      setCurrentCall(call);
  
      // Enhanced call event handlers with ref validation
      call.onEstablished = () => {
        console.log('Call established', {
          localVideoElement: localVideoRef?.current ? 'available' : 'missing',
          remoteVideoElement: remoteVideoRef?.current ? 'available' : 'missing'
        });
        setCallStatus('Connecting...');
      };
      
      call.onConnected = () => {
        console.log('Call connected!');
        setCallStatus('In call');
        
        // Validate that media views are still valid
        if (!localVideoRef?.current || !remoteVideoRef?.current) {
          console.error('Video refs lost during call connection!');
        }
        
        // Double-check media is flowing to remote video
        if (remoteVideoRef?.current) {
          const videoTracks = remoteVideoRef.current.srcObject?.getVideoTracks() || [];
          const audioTracks = remoteVideoRef.current.srcObject?.getAudioTracks() || [];
          
          console.log('Remote video media status:', {
            hasVideoTracks: videoTracks.length > 0,
            hasAudioTracks: audioTracks.length > 0,
            videoEnabled: videoTracks[0]?.enabled,
            audioEnabled: audioTracks[0]?.enabled
          });
          
          if (videoTracks.length === 0) {
            console.warn('No video tracks in remote stream - attempting refresh');
            
            // Force media element to update
            setTimeout(() => {
              if (remoteVideoRef?.current && call.getRemoteParticipant()) {
                console.log('Refreshing remote video element');
                // This might help in some browsers
                remoteVideoRef.current.play().catch(e => 
                  console.warn('Remote video play error (expected):', e)
                );
              }
            }, 1000);
          }
        }
      };
      
      // Add special handler for remote media
      call.onRemoteMediaConnected = () => {
        console.log('Remote media connected event fired!');
        
        // Check if we have the remote video element
        if (remoteVideoRef?.current) {
          console.log('Attempting to refresh remote media view');
          // Sometimes forcing play helps
          setTimeout(() => {
            try {
              remoteVideoRef.current.play();
            } catch (e) {
              console.warn('Remote play attempt warning:', e);
            }
          }, 200);
        } else {
          console.error('Remote video ref lost when remote media connected!');
        }
      };

      return call;
    } catch (error) {
      console.error('Failed to start call:', error);
      setCallStatus('Call failed');
      throw error;
    }
  };

  const toggleAudio = (isEnabled) => {
    if (currentCall) {
      try {
        console.log(`Attempting to ${isEnabled ? 'enable' : 'disable'} microphone`);
        currentCall.muteMicrophone(!isEnabled);
        
        // Verify the mute state changed as expected
        setTimeout(() => {
          const isCurrentlyMuted = currentCall.isLocalAudioEnabled === false;
          console.log('Microphone mute status check:', {
            requestedState: isEnabled,
            actualState: !isCurrentlyMuted
          });
        }, 300);
        
        return true;
      } catch (error) {
        console.error('Error toggling microphone:', error);
        return false;
      }
    }
    console.warn('No active call to toggle audio');
    return false;
  };

  const toggleVideo = (isEnabled) => {
    if (currentCall) {
      try {
        console.log(`Attempting to ${isEnabled ? 'enable' : 'disable'} video`);
        if (isEnabled) {
          // 비디오 시작 (활성화)
          currentCall.startVideo();
        } else {
          // 비디오 정지 (비활성화)
          currentCall.stopVideo();
        }
        // Verify the video state changed as expected
        setTimeout(() => {
          const isCurrentlyEnabled = currentCall.isLocalVideoEnabled === true;
          console.log('Video status check:', {
            requestedState: isEnabled,
            actualState: isCurrentlyEnabled
          });
        }, 300);
        
        return true;
      } catch (error) {
        console.error('Error toggling video:', error);
        return false;
      }
    }
    console.warn('No active call to toggle video');
    return false;
  };

  const endCall = () => {
    if (currentCall) {
      currentCall.end();
      return true;
    }
    return false;
  };

  return {
    isAuthenticated,
    currentCall,
    incomingCall,
    callStatus,
    acceptCall,
    rejectCall,
    startCall,
    toggleAudio,
    toggleVideo,
    endCall
  };
};