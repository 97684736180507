import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import './ResultPage.css';

const results = {
  en: {
    A: "You are at very high risk. Please consult a medical professional immediately.",
    B: "You are at high risk. Consider scheduling a consultation with your doctor.",
    C: "You are at moderate risk. Maintain a healthy lifestyle and monitor your health.",
    D: "You are at low risk. Keep up the good work with your healthy habits!"
  },
  ko: {
    A: "초고위험군입니다. 즉시 의료 전문가와 상담하세요.",
    B: "고위험군입니다. 의사와 상담 일정을 잡는 것이 좋습니다.",
    C: "중위험군입니다. 건강한 생활습관을 유지하며 건강을 체크하세요.",
    D: "저위험군입니다. 현재 건강 상태를 잘 유지하고 계십니다!"
  },
  es: {
    A: "Tiene un riesgo muy alto. Consulte a un profesional médico de inmediato.",
    B: "Tiene un riesgo alto. Considere programar una consulta con su médico.",
    C: "Tiene un riesgo moderado. Mantenga un estilo de vida saludable y controle su salud.",
    D: "Tiene un riesgo bajo. ¡Siga con sus buenos hábitos de salud!"
  }
};

const ResultPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { totalScore, responses, email, language } = location.state || { totalScore: 0, responses: [], email: '', language: 'en' };
  const currentUrl = "https://medi-knocks.com/HowHealthy"; // 현재 페이지의 URL
  const thumbnailUrl = "https://medi-knocks.com/icons/web_icon/some_user_event_1.png"; // 썸네일 이미지 URL

  const getThumbnailUrl = () => {
    switch (language) {
      case 'es':
        return `¿Realmente soy saludable?`;
      case 'ko':
        return `나는 과연 건강할까?`;
      default:
        return `Am I really healthy?`;
    }
  };

  const getDescription = () => {
    switch (language) {
      case 'es':
        return `Verifica mi estado de salud y resultados.`;
      case 'ko':
        return `나의 건강 상태와 결과를 확인하세요.`;
      default:
        return `Check my health status and results.`;
    }
  };

  const getResultImage = () => {
    switch (language) {
      case 'es':
        return "../icons/web_icon/result_grade_es.png";
      case 'ko':
        return "../icons/web_icon/result_grade_kr.png";
      default:
        return "../icons/web_icon/result_grade_en.png";
    }
  };

  const getResultTitleText1 = () => {
    switch (language) {
      case 'es':
        return "¿Curioso <br/>sobre <br/>tus <br/>resultados?";
      case 'ko':
        return "당신의 <br/>결과가 <br/>궁금<br/>하신가요?";
      default:
        return "Curious <br/>about <br/>your <br/>results?";
    }
  };

  const getResultTitleText2 = () => {
    switch (language) {
      case 'es':
        return `¡Descarga la aplicación <br/>para verlos y recibir <br/><span class="RP-Result-title-text2-bold">una consulta con un médico!</span>`;
      case 'ko':
        return `지금 앱을 다운로드하면 <br/>결과와 함께 <br/><span class="RP-Result-title-text2-bold">의사와 상담이 가능합니다!</span>`;
      default:
        return `Download the app <br/>to view them and receive <br/><span class="RP-Result-title-text2-bold">a consultation with a doctor!</span>`;
    }
  };

  const getResultSubTitle1Text = () => {
    switch (language) {
      case 'es':
        return `Aquellos que se registren a través de esta <br/><class="RP-Result-title-text2-bold">prueba pueden recibir una consulta gratuita con un médico.</span>`;
      case 'ko':
        return `이 테스트를 통해 가입하신 분들께는 <br/><span class="RP-Result-title-text2-bold">1회 무료로 의사 상담을 제공합니다.</span>`;
      default:
        return `Those who join through this test <br/><span class="RP-Result-title-text2-bold">can receive a free one-time consultation with a doctor.</span>`;
    }
  };

  const getResultSubTitle2Text = () => {
    switch (language) {
      case 'es':
        return '(※Debe registrarse usando el correo electrónico que proporcionó al iniciar la prueba.)';
      case 'ko':
        return '(※테스트를 시작할 때 작성하신 이메일로 가입해 주세요.)';
      default:
        return '(※You must sign up using the email you provided when starting the test.)';
    }
  };

  const getResultGradeText = () => {
    switch (language) {
      case 'es':
        return `- <span class="RP-Result-grade-text-D">Grado D</span>: “Alerta alta: consulta a un médico pronto”<br/>Tu salud está en riesgo, y es esencial que programes una cita con tu médico lo antes posible.<br/><br/>
                - <span class="RP-Result-grade-text-C">Grado C</span>: “Presta atención”<br/>Algunos aspectos de tu salud necesitan atención. Considera hacer ajustes en tu estilo de vida y monitorear tu salud.<br/><br/>
                - <span class="RP-Result-grade-text-B">Grado B</span>: “Estás bien, pero hay margen de mejora”<br/>Tu salud es buena en general, pero hay espacio para optimizarla aún más.<br/><br/>
                - <span class="RP-Result-grade-text-A">Grado A</span>: “¡Sigue así!” <br/>¡Tu salud está en excelente estado! Mantén tus hábitos actuales para conservar este nivel.<br/>`;
      case 'ko':
        return `- <span class="RP-Result-grade-text-D">D등급</span>: “고위험: 빠른 시일 내에 의사 상담이 필요합니다”<br/>건강 상태가 위험할 수 있으므로, <br/>빠른 시일 내에 의사와 상담해 보세요.<br/><br/>
                - <span class="RP-Result-grade-text-C">C등급</span>: “주의가 필요합니다”<br/>건강의 일부 측면에 주의가 필요합니다. <br/>생활 습관을 조정하고 모니터링을 고려해 주세요.<br/><br/>
                - <span class="RP-Result-grade-text-B">B등급</span>: “양호하지만 개선의 여지가 있습니다”<br/>건강이 대체로 좋지만, <br/>좀 더 최적화할 수 있는 부분이 있습니다.<br/><br/>
                - <span class="RP-Result-grade-text-A">A등급</span>: “현재처럼만 관리하세요!”<br/>건강 상태가 아주 좋습니다! <br/>현재의 생활 습관을 유지해 주세요.<br/>`;
      default:
        return `- <span class="RP-Result-grade-text-D">Grade D</span>: “High Alert: See a Doctor Soon”<br/>Your health is at risk, <br/>and it’s essential to schedule a check-up with your doctor as soon as possible.<br/><br/>
                - <span class="RP-Result-grade-text-C">Grade C</span>: “Take Notice”<br/>Some aspects of your health could use attention. <br/>Consider lifestyle adjustments and monitoring to stay on track.<br/><br/>      
                - <span class="RP-Result-grade-text-B">Grade B</span>: “Doing Fine, Room for Improvement”<br/>Your health is generally good, <br/>but there’s some room to optimize for even better well-being.<br/><br/>  
                - <span class="RP-Result-grade-text-A">Grade A</span>: “Keep Up the Great Work!”<br/>Your health is in excellent shape! <br/>Keep following your current habits to maintain this level.<br/>`;
    }
  };

  const TextWithHTML = ({ htmlContent }) => (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );

  const getResultBottomTitleText = () => {
    switch (language) {
      case 'es':
        return `¿Aún no te convence? <br><span class="RP-Result-title-text2-bold">¡Pruébalo descargándola ahora!</span> <br>Los nuevos usuarios pueden disfrutar del servicio al mejor precio con descuento.`;
      case 'ko':
        return `못 믿겠다고요? <br><span class="RP-Result-title-text2-bold">다운로드해 보세요!</span> <br>첫 가입 시 최고로 할인된 가격에 이용 가능합니다`;
      default:
        return `Still not convinced? <br><span class="RP-Result-title-text2-bold">Try downloading now!</span> <br>First-time users can enjoy the service at the best discounted price.`;
    }
  };

  const getRiskLevel = () => {
    if (totalScore >= 20) return 'A';
    if (totalScore >= 10) return 'B';
    if (totalScore >= 5) return 'C';
    return 'D';
  };

  const riskLevel = getRiskLevel();
  const resultMessage = results[language][riskLevel];

  // 링크 복사 기능
  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(currentUrl).then(() => {
      alert("링크가 복사되었습니다!");
    }).catch(err => {
      console.error("링크 복사 실패:", err);
    });
  };

  // 페이스북 공유
  const shareOnFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;
    window.open(facebookUrl, '_blank', 'noopener,noreferrer');
  };

  // 트위터(X) 공유
  const shareOnTwitter = () => {
    const text = encodeURIComponent("Check out my health results!"); // 공유할 기본 텍스트
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}&text=${text}`;
    window.open(twitterUrl, '_blank', 'noopener,noreferrer');
  };


  useEffect(() => {
    const sendDataToServer = async () => {
      try {
        await axios.post('/userEvent1', {
          email,
          userResponse: responses,
          score: totalScore,
          healthResult: riskLevel
        });
      } catch (error) {
        console.error("Error sending data to server:", error);
      }
    };

    sendDataToServer();
  }, [email, responses, totalScore, riskLevel]);

  const goToStartPage = () => {
    navigate('/HowHealthy');
  };

  const getStartOverButtonText = () => {
    switch (language) {
      case 'es':
        return 'Volver a empezar';
      case 'ko':
        return '다시 시작';
      default:
        return 'Start Over';
    }
  };

  return (
    <div className="RP-main-body">
      <Helmet>
        {/* 동적 제목 설정 */}
        <title>{getThumbnailUrl()}</title>

        {/* Open Graph 메타 태그 */}
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={getThumbnailUrl()} />
        <meta property="og:description" content={getDescription()} />
        <meta property="og:image" content={thumbnailUrl} />

        {/* Twitter 카드 메타 태그 */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={currentUrl} />
        <meta name="twitter:title" content={getThumbnailUrl()} />
        <meta name="twitter:description" content={getDescription()} />
        <meta name="twitter:image" content={thumbnailUrl} />
      </Helmet>

      <div className="RP-main-container">
        <div className="RP-result-top">
          <img
            src={getResultImage()}
            alt="result img"
            className="RP-result-image"
          />
          {/* <div className="RP-Result-title-text">
            <TextWithHTML htmlContent={getResultTitleText1()} />
          </div> */}
        </div>

        <div className="RP-Result-middle">
          <p className="RP-Result-grade-text">
            <TextWithHTML htmlContent={getResultGradeText()} />
          </p>
        </div>

        <div className="RP-main-result-top">
          <h1 className="RP-Result-title-text2">
            <TextWithHTML htmlContent={getResultTitleText2()} />
          </h1>
          <p className="RP-Result-sub-text-1">
            <TextWithHTML htmlContent={getResultSubTitle1Text()} />
          </p>
          <p className="RP-Result-sub-text-2">
            <TextWithHTML htmlContent={getResultSubTitle2Text()} />
          </p>
        </div>

        <div className="RP-Result-bottom">
          {/* <h1 className="RP-Result-bottom-title-text">
            <TextWithHTML htmlContent={getResultBottomTitleText()} />
          </h1> */}
          <div className="RP-store-budge-group">
            <a href = "https://apps.apple.com/us/app/mediknocks/id6737291462">
              <img src="/icons/web_icon/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="apple Logo" className="RP-cta-button1" />
            </a>
            <a href = "https://play.google.com/store/apps/details?id=com.mediknocksapp.login">
              <img src="/icons/web_icon/google-play-badge.png" alt="google Logo" className="RP-cta-button2" />
            </a>
          </div>

          <div className="RP-share-buttons">
                <img src="/icons/web_icon/upload_icon.png" alt="link copy" onClick={copyLinkToClipboard} className="RP-share-button1"/>
                <img src="/icons/web_icon/Facebook_Logo_Primary.png" alt="facebook" className="RP-share-button2" onClick={shareOnFacebook}/>
                <img src="/icons/web_icon/sns_x_logo.png" alt="x" onClick={shareOnTwitter} className="RP-share-button3"/>
          </div>

          <button onClick={goToStartPage} className="RP-btn-retry">{getStartOverButtonText()}</button>
        </div>
      </div>
    </div>
  );
};

export default ResultPage;
