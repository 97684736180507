import '../css/DoctorProfile.css';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import SendBird from 'sendbird';
import { useLocation, useNavigate } from 'react-router-dom';
import NotificationComponent from './NotificationComponent';
import logoImage from './icons/Logo.png';
import notificationIcon from './icons/notification.png';
import LogoutModal from './LogoutModal';
import MedicalDepartmentModal from './MedicalDepartmentModal';
import DoctorProfileNotification from './DoctorProfileNotification';
import searchIcon from './icons/search-icon.png';
import * as pdfjsLib from 'pdfjs-dist';
import Sidebar from './Sidebar_Pre';
import AccountSection from './AccountSection';
import CustomDatePicker from './DoctorDatePicker';
import { useLoadScript } from '@react-google-maps/api';
import "react-datepicker/dist/react-datepicker.css";
import lisenceCheck from './icons/licenseCheck.png'
import { useSound } from '../hooks/useSound';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const libraries = ['places'];
// PDF.js worker setup
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const US_STATES = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' }
];

const sb = new SendBird({ appId: 'D3977B5D-9B96-4530-A606-88DA7E02906A' });
const IP = process.env.REACT_APP_HOST;

function DoctorProfile() {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [showDepartmentModal, setShowDepartmentModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const email = localStorage.getItem('email');
  const rememberMe = localStorage.getItem('rememberMe');
  const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);
  const stateDropdownRef = useRef(null);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [inputStates, setInputStates] = useState({});
  const [showMedicalDepartmentModal, setShowMedicalDepartmentModal] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [formData, setFormData] = useState({ searchAddress: '' });
  const [licenseFile, setLicenseFile] = useState(null);
  const [licensePreview, setLicensePreview] = useState(null);
  const fileInputRef = useRef(null);
  const [activeMenu, setActiveMenu] = useState('editProfile');
  const [tempProfileImage, setTempProfileImage] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [userCalls, setUserCalls] = useState([]);
  const [isChatActive, setIsChatActive] = useState(false);
  const callSound = new Audio('/OccureCall.wav');
  const [sound, setSound] = useState();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'en',
  });
  const [searchAddress, setSearchAddress] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const autocompleteService = useRef(null);
  const placesService = useRef(null);

  const handleAcceptCall = async (userCall) => {
    try {
      const response = await axios.post('/DrChatMain/callAccept', {
        userEmail: userCall.userId,
        drEmail: email,
      });

      if (response.status === 200) {
        navigate('/WebChat');
      }
    } catch (error) {
      console.error('Error accepting user call:', error);
      if (error.response && error.response.status === 400) {
        alert('This call has expired');
      }
    }
  };

  const handleCancelCall = (userCall) => {
    console.log('Call cancelled:', userCall);
  };

  useEffect(() => {
    const eventSource = new EventSource(`/api/sse?email=${email}`);
    
    eventSource.onmessage = (event) => {
      try {
        const newEvent = JSON.parse(event.data);
        if (newEvent.length > userCalls.length) {
          if (callSound) {
            callSound.muted = !sound; // sound 상태에 따라 음소거 설정
            callSound.play().catch(error => {
              console.error('Error playing sound:', error);
            });
          }
        }
        setUserCalls(newEvent);
      } catch (e) {
        console.error('Failed to parse event data:', e);
      }
    };
  
    eventSource.onerror = (error) => {
      console.error('EventSource failed: ', error);
      eventSource.close();
    };
  
    return () => {
      eventSource.close();
    };
  }, [email, userCalls.length, sound]);

  useEffect(() => {
    if (isLoaded && !autocompleteService.current) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      placesService.current = new window.google.maps.places.PlacesService(document.createElement('div'));
    }
  }, [isLoaded]);

  const menuItems = [
    { name: 'editProfile', label: 'Edit Profile', path: '/editProfile' },
    { name: 'account', label: 'Account', path: '/account' },
    { name: 'income', label: 'Income', path: '/pay' },
    { name: 'help', label: 'Help', path: '/help' }
  ];

  const handleNavigate = (path, menuName) => {
    if (path === '/WebChat') {
      navigate('/WebChat');
    } else if ( path === '/PreWebChatPage'){
      navigate('/PreWebChatPage');
    }else if (path === -1) {
      navigate(-1);
    } else {
      navigate(path);
      setActiveMenu(menuName);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value.replace(/\D/g, ''); // 숫자만 추출
    let formattedNumber = '';
    if (input.length > 0) {
      formattedNumber = '(' + input.substr(0, 3);
      if (input.length > 3) {
        formattedNumber += ') ' + input.substr(3, 3);
      }
      if (input.length > 6) {
        formattedNumber += '-' + input.substr(6, 4);
      }
    }
    setData(prevData => ({
      ...prevData,
      dr_phonenumber: formattedNumber
    }));
  };

  const convertPdfToImage = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const maxWidth = 800;
      const maxHeight = 600;

      const ratio = Math.min(maxWidth / viewport.width, maxHeight / viewport.height);

      canvas.width = viewport.width * ratio;
      canvas.height = viewport.height * ratio;

      const scaledViewport = page.getViewport({ scale: ratio });

      await page.render({ canvasContext: context, viewport: scaledViewport }).promise;
      return canvas.toDataURL();
    } catch (error) {
      console.error('Error converting PDF to image:', error);
      return null;
    }
  };

  const handleFileUpload = async (file) => {
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        alert("File size exceeds 5MB limit.");
        return;
      }

      const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        alert("Invalid file type. Please upload a JPG, PNG, or PDF file.");
        return;
      }

      let preview;
      if (file.type === 'application/pdf') {
        preview = await convertPdfToImage(file);
        if (!preview) {
          alert("Failed to convert PDF to image.");
          return;
        }
      } else {
        preview = URL.createObjectURL(file);
      }

      setLicenseFile(file);
      setLicensePreview(preview);
    }
  };

  const handleLicenseUpdate = (e) => {
    const file = e.target.files[0];
    handleFileUpload(file);
  };

  const handleRemoveLicense = () => {
    setLicenseFile(null);
    setLicensePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const formatBirthDate = () => {
    if (!data.dr_day || !data.dr_month || !data.dr_year) return '';
    const day = String(data.dr_day).padStart(2, '0');
    const month = String(data.dr_month).padStart(2, '0');
    return `${day}/${month}/${data.dr_year}`;
  };

  const handleBirthDateChange = (dateStr) => {
    const [day, month, year] = dateStr.split('/');
    setData(prevData => ({
      ...prevData,
      dr_day: day,
      dr_month: month,
      dr_year: year
    }));
  };

  const handleLogoutClick = () => {
    setShowLogoutModal(true);
  };

  const handleAddressInputChange = useCallback((e) => {
    const value = e.target.value;
    setSearchAddress(value);  // searchAddress state 업데이트
    setIsSearching(true);     // 검색 중 상태 설정

    if (value.length > 0 && autocompleteService.current) {
      autocompleteService.current.getPlacePredictions(
        {
          input: value,
          componentRestrictions: { country: 'us' },
          types: ['address']  // 주소 타입으로 제한
        },
        (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setPredictions(results);
          } else {
            setPredictions([]);
          }
          setIsSearching(false);  // 검색 완료 후 상태 업데이트
        }
      );
    } else {
      setPredictions([]);
      setIsSearching(false);
    }
  }, []);

  const getAddressComponent = useCallback((components, type) => {
    const component = components.find(comp => comp.types.includes(type));
    return component ? component.long_name : '';
  }, []);

  const handleSelectPlace = useCallback((placeId, e) => {
    if (placesService.current) {
      placesService.current.getDetails(
        { placeId: placeId },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
            const addressComponents = place.address_components || [];

            // 주소 컴포넌트에서 필요한 정보 추출
            const zipCode = getAddressComponent(addressComponents, 'postal_code');
            const city = getAddressComponent(addressComponents, 'locality');
            const state = getAddressComponent(addressComponents, 'administrative_area_level_1');
            const stateCode = US_STATES.find(s => s.label === state)?.value || '';

            // 데이터 업데이트
            setData(prevData => ({
              ...prevData,
              dr_zipcode: zipCode,
              dr_city: city,
              dr_state: stateCode,
              dr_address: place.formatted_address,
            }));

            setSearchAddress(place.formatted_address);
            setPredictions([]);
          }
        }
      );
    }
  }, [setData]);

  const handleInputFocus = (name) => {
    setInputStates(prev => ({
      ...prev,
      [name]: 'focused'
    }));
  };

  const handleInputBlur = (name) => {
    const value = data[name];
    setInputStates(prev => ({
      ...prev,
      [name]: value && value.length > 0 ? 'filled' : 'empty'
    }));
  };

  const handleStateChange = (stateValue) => {
    handleInputChange({ target: { name: 'state', value: stateValue } });
    setIsStateDropdownOpen(false);
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(`/Save/getDrInfo`, { drEmail: email }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      let fetchedData = response.data.dr;

      setSound(fetchedData.dr_soundActive || false);

      // Ensure dr_medical_department is always an array
      if (fetchedData.dr_medical_department) {
        if (typeof fetchedData.dr_medical_department === 'string') {
          fetchedData.dr_medical_department = fetchedData.dr_medical_department.split(',').map(dept => dept.trim());
        } else if (!Array.isArray(fetchedData.dr_medical_department)) {
          console.error('Unexpected type for dr_medical_department:', typeof fetchedData.dr_medical_department);
          fetchedData.dr_medical_department = [];
        }
      } else {
        fetchedData.dr_medical_department = [];
      }

      setData(fetchedData);
      console.log(fetchedData);
      console.log("DoctorProfile getData!!");
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData(prevData => ({
      ...prevData,
      [name === 'dr_type' ? 'dr_doctorType' : name]: value
    }));
    setTouched(prev => ({ ...prev, [name]: true }));
    // Implement validation logic here to update errors state
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
  
    try {
      // FormData 생성
      const formData = new FormData();
      formData.append('email', email);
  
      // 프로필 사진 처리
      if (tempProfileImage) {
        formData.append('profilePicture', tempProfileImage);
      }
  
      // 라이센스 파일 처리
      if (licenseFile) {
        formData.append('licenseFile', licenseFile);
      }
  
      // 파일 업로드 처리
      if (tempProfileImage || licenseFile) {
        const uploadResponse = await axios.post(`/Save/uploadProfile`, formData, {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        // 서버 응답에서 URL을 받아와서 data 객체 업데이트
        if (uploadResponse.data.profilePhotoUrl) {
          data.dr_profile_photo = uploadResponse.data.profilePhotoUrl;
        }
        if (uploadResponse.data.licenseFileUrl) {
          data.dr_license_verify = uploadResponse.data.licenseFileUrl;
        }
      }
  
      // 의료 부서 정보 저장
      if (selectedDepartments.length > 0) {
        await axios.post(`/Save/MedicalDep`, {
          email: email,
          MedicalDepItem: selectedDepartments
        }, {
          withCredentials: true,
        });
      }
  
      // 기본 의사 정보 업데이트
      const updatedData = {
        email: email,
        firstName: data.dr_firstname,
        lastName: data.dr_lastname,
        dateOfBirth: formatBirthDate(),
        gender: data.dr_gender,
        phoneNumber: data.dr_phonenumber,
        city: data.dr_city,
        state: data.dr_state,
        zipcode: data.dr_zipcode,
        doctorType: data.dr_doctorType,
        hospitalAddressLine1: data.dr_address,
        hospitalAddressLine2: data.dr_address2,
        experience: data.dr_info_experience,
        licenseInfo: data.dr_info_license,
        contactInfo: data.dr_info_contact,
        selfIntroduction: data.dr_self_introduction,
        closingRemarks: data.dr_self_outro,
        profilePhotoUrl: data.dr_profile_photo,
        licenseFileUrl: data.dr_license_verify
      };
  
      await axios.post(`/Save/updateDrInfo`, updatedData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      // 주의: 여기서 tempProfileImage와 profileImagePreview는 초기화하지 않음
      // 라이센스 파일 관련 상태만 초기화
      setLicenseFile(null);
      setLicensePreview(null);
  
      // 서버에서 새 데이터를 가져오되, 이미지 관련 상태는 유지
      const response = await axios.post(`/Save/getDrInfo`, { drEmail: email }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      let fetchedData = response.data.dr;
  
      // 프로필 이미지는 로컬 미리보기 유지
      if (profileImagePreview) {
        fetchedData.dr_profile_photo = profileImagePreview;
      }
  
      // medical department 처리
      if (fetchedData.dr_medical_department) {
        if (typeof fetchedData.dr_medical_department === 'string') {
          fetchedData.dr_medical_department = fetchedData.dr_medical_department.split(',').map(dept => dept.trim());
        } else if (!Array.isArray(fetchedData.dr_medical_department)) {
          fetchedData.dr_medical_department = [];
        }
      } else {
        fetchedData.dr_medical_department = [];
      }
  
      setData(fetchedData);
  
    } catch (error) {
      console.error('Error updating profile:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleImageUpdate = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // 파일 유효성 검사
    if (!file.type.startsWith('image/')) {
      alert('Please upload an image file');
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      alert('File size must be less than 5MB');
      return;
    }

    // 파일을 state에 저장
    setTempProfileImage(file);

    // 미리보기 URL 생성
    const previewURL = URL.createObjectURL(file);
    setProfileImagePreview(previewURL);
  };

  const handleLogoutConfirm = async () => {
    try {
      await axios.post(`/Login/logout`, {}, { withCredentials: true });
      await sb.disconnect();
      if (!rememberMe) {
          localStorage.removeItem('email');
          localStorage.removeItem('rememberMe');
      }
      window.location.href = '/Login';
    } catch (error) {
      console.error('Error logout:', error);
      alert('Failed to logout');
    } finally {
      setShowLogoutModal(false);
    }
  };

  const handleOpenMedicalDepartmentModal = () => {
    setShowMedicalDepartmentModal(true);
  };

  const handleCloseMedicalDepartmentModal = () => {
    setShowMedicalDepartmentModal(false);
  };

  const handleDepartmentChange = (department) => {
    setSelectedDepartments(prev =>
      prev.includes(department)
        ? prev.filter(dep => dep !== department)
        : [...prev, department]
    );
  };

  const handleSaveDepartments = async () => {
    setData(prevData => ({
      ...prevData,
      dr_medical_department: selectedDepartments
    }));
    setShowMedicalDepartmentModal(false);
  };

  const toggleSound = async () => {
    try {
      // DB 업데이트
      await axios.post('/Save/updateSound', {
        email: email,
        soundActive: !sound
      }, {
        withCredentials: true
      });
  
      // 상태 업데이트
      setSound(!sound);
      
      // 음소거 상태 업데이트
      if (callSound) {
        callSound.muted = sound;
      }
    } catch (error) {
      console.error('Error updating sound settings:', error);
    }
  };

  useEffect(() => {
    if (data.dr_medical_department) {
      if (typeof data.dr_medical_department === 'string') {
        setSelectedDepartments(data.dr_medical_department.split(',').map(dept => dept.trim()));
      } else if (Array.isArray(data.dr_medical_department)) {
        setSelectedDepartments(data.dr_medical_department);
      } else {
        console.error('Unexpected type for dr_medical_department:', typeof data.dr_medical_department);
        setSelectedDepartments([]);
      }
    } else {
      setSelectedDepartments([]);
    }
  }, [data.dr_medical_department]);

  useEffect(() => {
    const currentPath = location.pathname;
    const currentMenu = menuItems.find(item => item.path === currentPath);
    if (currentMenu) {
      setActiveMenu(currentMenu.name);
    }
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      // 컴포넌트 언마운트 시 미리보기 URL 정리
      if (profileImagePreview) {
        URL.revokeObjectURL(profileImagePreview);
      }
    };
  }, [profileImagePreview]);

  const goMain = () =>{
    navigate('/PreWebChatPage');
    //window.location.reload();
  }

  return (
    <div className="dpp-doctor-profile">
      <div className="wcc-header">
        <div className="wcc-header-left">
        <button onClick={goMain} style={{background: 'none', border: 'none', cursor: 'pointer'}}>
            <img src={logoImage} alt="MEDINOX Logo" className="wcc-logo-image" />
          </button>
        </div>
        <div className="wcc-header-right">
          <button 
            className='wcc_sounds'
            onClick={toggleSound}
            style={{display:"contents"}}>
            <img 
              src={sound ? "./icons/web_icon/sound_on.png" : "./icons/web_icon/sound_mute.png"} 
              alt={sound ? "Sound On" : "Sound Off"} 
              style={{width:"32px", height:"auto"}}
            />
          </button>
          <button className="wcc-notification-button">
            <DoctorProfileNotification
              userCalls={userCalls}
              handleAcceptCall={handleAcceptCall}
              handleCancelCall={handleCancelCall}
            />
          </button>
          <div className="wcc-rate-container">
            <img src="./icons/web_icon/img_rate.png" alt="TTABONG" className="wcc-rate-img" />
            <div className="wcc-rate-text"><span style={{ color: "#01B6CD" }}>{data.dr_rating}</span> / 5</div>
          </div>
          <button className="wcc-drprofile-button">
            <img
              loading="lazy"
              src={data.dr_profile_photo}
              alt="Doctor Profile"
              className="wcc-drprofile-img"
            />
          </button>
        </div>
      </div>
      <div className="dpp-content">
        <Sidebar
          handleLogoutClick={handleLogoutClick}
          onNavigate={handleNavigate}
          activeMenu={activeMenu}
          menuItems={menuItems}
        />
        <main className="dpp-main-contentA">
          {activeMenu === 'editProfile' && (
            <div className="dpp-sub-containerA">
              <div className='dp_MainTitle'>Your Profile</div>
              <div className='dp_section1'>
                <div style={{ textAlign: 'center' }}>
                  <div className='dp_drPicContainer'>
                    {profileImagePreview ? (
                      <img
                        src={profileImagePreview}
                        alt="Doctor Profile"
                        className="dp_profile-image"
                      />
                    ) : data.dr_profile_photo ? (
                      <img
                        src={data.dr_profile_photo}
                        alt="Doctor Profile"
                        className="dp_profile-image"
                      />
                    ) : (
                      <div className="dp_profile-picture-placeholder">
                        <img
                          src="/icons/web_icon/user-icon.png"
                          alt="User Icon"
                          className="dp_user-icon"
                        />
                      </div>
                    )}
                    <input
                      id="dp_profile-upload"
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpdate}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="dp_profile-upload" className="dp_camera-icon-overlay">
                    </label>
                  </div>

                  {/* Select Image 버튼은 동일한 input을 트리거하도록 수정 */}
                  <button
                    className='dp_uploadBtn'
                    onClick={(e) => {
                      e.preventDefault(); // 기본 동작 방지
                      document.getElementById('dp_profile-upload').click();
                    }}
                  >
                    Image update
                  </button>
                </div>
                <div className='dp_mainContents'>
                  <div className='dp_Account'>
                    <div className='dp_label'>Modify profile picture</div>
                    <div className='dp_container padding_Bottom20'>
                      <div className="dp-input-wrapper">
                        <label htmlFor="dr_firstname">First Name</label>
                        <input
                          type="text"
                          className='dp_textarea_A'
                          name="dr_firstname"
                          id="dr_firstname"
                          value={data.dr_firstname || ''}
                          onChange={handleInputChange}
                          placeholder=" "
                        />
                      </div>
                      <div className="dp-input-wrapper">
                        <label htmlFor="dr_lastname">Last Name</label>
                        <input
                          type="text"
                          className='dp_textarea_A'
                          name="dr_lastname"
                          id="dr_lastname"
                          value={data.dr_lastname || ''}
                          onChange={handleInputChange}
                          placeholder=" "
                        />
                      </div>
                      <div className="dp-input-wrapper state-select-wrapper">
                        <label htmlFor="dr_gender">Gender</label>
                        <div className="custom-select">
                          <select
                            className='dp_state'
                            name="dr_gender"
                            id="dr_gender"
                            value={data.dr_gender || ''}
                            onChange={handleInputChange}
                          >
                            <option value="" disabled hidden></option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='dp_container'>
                      <div className="dp-input-wrapper">
                        <label htmlFor="dr_birthdate">Date of Birth</label>
                        <CustomDatePicker
                          selected={formatBirthDate()}
                          onChange={handleBirthDateChange}
                          hasError={false}
                        />
                      </div>
                      <div className="dp-input-wrapper phone-number-wrapper">
                        <label htmlFor="dr_phonenumber">Phone Number</label>
                        <input
                          type="tel"
                          className='dp_textarea_C'
                          name="dr_phonenumber"
                          id="dr_phonenumber"
                          value={data.dr_phonenumber || ''}
                          onChange={handlePhoneNumberChange}
                          placeholder="(000) 000-0000"
                          maxLength={14}
                        />
                      </div>
                    </div>
                    <div className="dpp-radio-group">
                      <label className="dpp-radio-label">
                        <input
                          type="radio"
                          name="dr_type"
                          value="affiliated"
                          checked={data.dr_doctorType === 'affiliated'}
                          onChange={handleInputChange}
                        />
                        <span className="dpp-radio-custom"></span>
                        Affiliated doctor
                      </label>
                      <label className="dpp-radio-label">
                        <input
                          type="radio"
                          name="dr_type"
                          value="clinicOwner"
                          checked={data.dr_doctorType === 'clinicOwner'}
                          onChange={handleInputChange}
                        />
                        <span className="dpp-radio-custom"></span>
                        Clinic owner
                      </label>
                      <label className="dpp-radio-label">
                        <input
                          type="radio"
                          name="dr_type"
                          value="unaffiliated"
                          checked={data.dr_doctorType === 'unaffiliated'}
                          onChange={handleInputChange}
                        />
                        <span className="dpp-radio-custom"></span>
                        Unaffiliated doctor
                      </label>
                    </div>
                  </div>

                  <div className='dp_Medical'>
                    <div className="dp-search-container">
                      <div className="dp-search-input-wrapper">
                        <input
                          type="text"
                          placeholder="Search address..."
                          value={searchAddress}
                          onChange={handleAddressInputChange}
                          className="dp-address-search-input"
                        />
                      </div>
                      {predictions.length > 0 && (
                        <ul className="dp-predictions-list">
                          {predictions.map((prediction) => (
                            <li
                              key={prediction.place_id}
                              onClick={() => handleSelectPlace(prediction.place_id)}
                              className="dp-prediction-item"
                            >
                              <span>{prediction.description}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>

                    <div className="dp-address-details">
                      <div className="dp-input-wrapper">
                        <label htmlFor="dr_firstname">Zip code</label>
                        <input
                          type="text"
                          className='dp_textarea_A'
                          name="dr_zipcode"
                          id="dr_zipcode"
                          value={data.dr_zipcode || ''}
                          onChange={handleInputChange}
                          onFocus={() => handleInputFocus('dr_zipcode')}
                          onBlur={() => handleInputBlur('dr_zipcode')}
                          placeholder="ZipCode"
                        />
                      </div>
                      <div className="dp-input-wrapper">
                        <label htmlFor="dr_city">City</label>
                        <input
                          type="text"
                          name="dr_city"
                          id="dr_city"
                          placeholder="City"
                          value={data.dr_city || ''}
                          onChange={handleInputChange}
                          onFocus={() => handleInputFocus('dr_city')}
                          onBlur={() => handleInputBlur('dr_city')}
                          className='dp_textarea_A'
                        />
                        {touched.dr_city && errors.dr_city && <div className="dp-error-text">{errors.dr_city}</div>}
                      </div>
                      <div className="dp-input-wrapper state-select-wrapper">
                        <label htmlFor="dr_state">State</label>
                        <div className="custom-select">
                          <select
                            className='dp_state'
                            name="dr_state"
                            id="dr_state"
                            value={data.dr_state || ''}
                            onChange={handleInputChange}
                          >
                            <option value="" disabled hidden></option>
                            {US_STATES.map(state => (
                              <option key={state.value} value={state.value}>
                                {state.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='dp_container padding_Bottom20'>
                      <div className="dp-input-wrapper">
                        <label htmlFor="dr_firstname">Hospital Address</label>
                        <input
                          type="text"
                          className='dp_textarea_C'
                          name="dr_address"        // dr_HospitalAddress에서 dr_address로 수정
                          id="dr_address"
                          value={data.dr_address || ''}
                          onChange={handleInputChange}
                          placeholder=" "
                        />
                      </div>
                    </div>
                    <div className='dp_container padding_Bottom20'>
                      <div className="dp-input-wrapper">
                        <label htmlFor="dr_HospitalAddress2">Hospital Address line 2 (optional)</label>
                        <input
                          type="text"
                          className='dp_textarea_C'
                          name="dr_address2"      // dr_HospitalAddress2에서 dr_address2로 수정
                          id="dr_address2"
                          value={data.dr_address2 || ''}
                          onChange={handleInputChange}
                          placeholder=" "
                        />
                      </div>
                    </div>
                    <hr />
                    <div className='dp_medicalLisenceContainer'>
                      <div className='dp_MedicalContainer'>
                        <div className='dp_label'>Medical department</div>
                        <button className='dp_MedicalBtn' onClick={handleOpenMedicalDepartmentModal}>
                          <img className='dp_image' src="./icons/web_icon/img_iconMedicalDepartment.png" alt="Medical department icon" />
                          <span>Medical department</span>
                        </button>
                      </div>

                      <div className='dp_LicenseContainer'>
                        <div className='dp_label' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          License Authentication
                          {(data.dr_license || licenseFile) && <img src={lisenceCheck} style={{ marginLeft: '5px', width: '16px', height: '16px' }}></img>}
                        </div>
                        <div className="dp_license-preview-container">
                          {licensePreview || data.dr_license_verify ? (
                            <img
                              src={licensePreview || `${data.dr_license_verify}`}
                              alt="License Preview"
                              className="dp_license-preview"
                            />
                          ) : (
                            <div className="dp_license-placeholder">
                              Upload your license file (jpg, png, pdf only)
                            </div>
                          )}
                        </div>
                        <div className="dp_license-filename" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <span style={{
                            width: '240px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}>
                            {licenseFile ? licenseFile.name : (data.dr_license_verify ? data.dr_license_verify.split('/').pop() : 'No file chosen')}
                          </span>
                          {licenseFile &&
                            <button
                              onClick={handleRemoveLicense}
                              style={{
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                padding: '0 5px',
                                marginLeft: '10px',
                                fontSize: '16px'
                              }}
                            >
                              ×
                            </button>
                          }
                        </div>
                        <button
                          className="dp_update-license"
                          onClick={() => fileInputRef.current.click()}
                        >
                          Update
                        </button>
                        <input
                          ref={fileInputRef}
                          type="file"
                          accept=".jpg,.jpeg,.png,.pdf"
                          onChange={handleLicenseUpdate}
                          style={{ display: 'none' }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='dp_Experience'>
                    <div className='dp_label'>Professional Experience</div>
                    <div className='dp_container padding_Bottom20'>
                      <div className="dp-input-wrapper3">
                        <label htmlFor="dr_Experience">Experience</label>
                        <textarea
                          className='dp_textarea_C2 dp_ExperienceTextBox'
                          name="dr_info_experience"
                          id="dr_info_experience"
                          value={data.dr_info_experience || ''}
                          onChange={handleInputChange}
                          placeholder=" "
                        />
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div className='dp_introduction'>
                    <div className='dp_label'>Self-introduction</div>
                    <textarea
                      className='dp_textarea_E padding_Bottom20'
                      name="dr_self_introduction"
                      value={data.dr_self_introduction || ''}
                      onChange={handleInputChange}
                      placeholder="Write your self-introduction here"
                    ></textarea>
                  </div>

                  <div className='dp_CloseRemarks'>
                    <div className='dp_label'>Closing Remarks</div>
                    <textarea
                      className='dp_textarea_E padding_Bottom20'
                      name="dr_self_outro"
                      value={data.dr_self_outro || ''}
                      onChange={handleInputChange}
                      placeholder="Write your closing remarks here"
                    ></textarea>
                  </div>
                  <button
                    className={`dp_saveBtn ${isSaving ? 'dp_loading-button' : ''}`}
                    onClick={handleSubmit}
                    disabled={isSaving}
                  >
                    <span className={`dp_button-text ${isSaving ? 'dp_loading' : ''}`}>
                      Save
                    </span>
                    {isSaving && <div className="dp_loading-spinner"></div>}
                  </button>
                </div>
              </div>
            </div>
          )}
          {activeMenu === 'account' && (
            <AccountSection email={email} />
          )}
        </main>
        <MedicalDepartmentModal
          isOpen={showMedicalDepartmentModal}
          onClose={handleCloseMedicalDepartmentModal}
          selectedDepartments={selectedDepartments}
          onDepartmentChange={handleDepartmentChange}
          onSave={handleSaveDepartments}
        />
      </div>
      {showLogoutModal && (
        <LogoutModal
          onClose={() => setShowLogoutModal(false)}
          onConfirm={handleLogoutConfirm}
        />
      )}
    </div>
  );
}

export default DoctorProfile;